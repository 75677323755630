<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>
    </svg>
    <SceneDialogue
      class="vid-cnr"
      bg="transparent"
      :width="0.422"
      :tx="0.14" :ty="0.575">
      <video ref="vid" class="vid" preload muted loop playsinline :src="`${publicPath}scenes/august-8-9/full-clip.mp4`" />
    </SceneDialogue>
    <SceneDialogue
			ref="d1"
      class="d1 white narrative"
      bg="transparent"
			:width="0.866" :tx="0.5" :ty="0.15">
      <p>
        <span ref="n1" :style="{lineHeight: `1.25em`, fontSize: `1.5em`}" class="n1 opti-morgan">On the next excursion,</span>
        <span ref="n2"> Ray hikes down Tsegi Canyon to visit a team of Expedition
        members camped in Cobra Head Canyon.</span> <span ref="n3" class="n3">Ray meets Jerry Hanson and his mapping and
        survey crew. He gets to work taking movie footage of them.</span>
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="d2"
      class="d2"
			:width="0.35" :tx="0.875" :ty="0.575"
      bg="transparent"
      className="entry">
			<h2 class="entry-title futura-passata">August 8-9, 1937</h2>
			<p><em>
        “Arrived at the camp at 11:00. Got talking to Hansen about mapping. 
        ...Williamson and Hansen will spend the day running levels for the topographic
        map.”
			</em></p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-8.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    const { vid, d1, d2, n1, n2, n3 } = this.$refs
    /* must set with gsap since tl is paused */
    // gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(vid, { opacity: 0 })
    // gsap.set(d1.$el, { opacity: 0 })
    gsap.set(d2.$el, { opacity: 0 })
    gsap.set(n1, { opacity: 0 })
    gsap.set(n2, { opacity: 0 })
    gsap.set(n3, { opacity: 0 })
    // tl.to(scene], { duration: 1, opacity: 1 })
    tl.to(n1, { duration: 1, opacity: 1 })
    tl.to(n2, { duration: 1, opacity: 1 })
    tl.to(n3, { duration: 1, opacity: 1 })
    // tl.to(d1.$el, { duration: 1, opacity: 1 })
    tl.to(vid, { duration: 1, opacity: 1 }, "+=1")
    tl.add(vid.play())
    tl.to(d2.$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.entry-title {
		margin-bottom: 0.5em;
	}
  .entry {
    color: white;
  }
  .d1 {
    p { font-size: 1.1em; }
  }
  .d2 {
    p { font-size: 1.125em; }
  }
  .vid {
    width: 24em;
    height: auto;
    display: block;
  }
  .vid-cnr {
    padding: 0;
  }
</style>
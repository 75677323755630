import "./polyfill"
import { createApp } from 'vue'
import App from './App.vue'
import store     from "./store/"
import router    from "./router/"
import gtag from "vue-gtag"

createApp(App)
  .use(router)
  .use(store)
  .use(gtag, {
    config: { id: "G-Q5NGCQXXTF" }
  }, router)
  .mount('#app')
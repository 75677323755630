<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight"/>
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>
      <g ref="images" clip-path="url(#clip-frame)">
        <image :width="693" :height="925" :x="472" :y="34" :xlink:href="`${publicPath}scenes/present-day/IMG_4416.jpg`"/>
        <image :width="569" :height="759" :x="960" :y="117" :xlink:href="`${publicPath}scenes/present-day/IMG_4418.jpg`"/>
        <image :width="905" :height="678" :x="284" :y="81" :xlink:href="`${publicPath}scenes/present-day/IMG_4576.jpg`"/>
        <image :width="806" :height="604" :x="170" :y="374" :xlink:href="`${publicPath}scenes/present-day/IMG_4591.jpg`"/>
        <image :width="961" :height="720" :x="555" :y="60" :xlink:href="`${publicPath}scenes/present-day/IMG_4595.jpg`"/> 
        <image :width="666" :height="500" :x="240" :y="557" :xlink:href="`${publicPath}scenes/present-day/IMG_4618.jpg`"/>
        <image :width="885" :height="663" :x="894" :y="66" :xlink:href="`${publicPath}scenes/present-day/IMG_4619.jpg`"/>
        <image :width="896" :height="672" :x="107" :y="204" :xlink:href="`${publicPath}scenes/present-day/IMG_4720.jpg`"/>
        <image :width="875" :height="657" :x="960" :y="374" :xlink:href="`${publicPath}scenes/present-day/IMG_4775.jpg`"/>
        <image :width="605" :height="454" :x="1095" :y="117" :xlink:href="`${publicPath}scenes/present-day/IMG_4722.jpg`"/>
        <image :width="814" :height="610" :x="702" :y="54" :xlink:href="`${publicPath}scenes/present-day/IMG_4419.jpg`"/>
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 narrative white"
      bg="transparent"
			:width="0.5" :tx="0.033" :ty="0.1">
      <p>
        The Darkroom Today
      </p>
    </SceneDialogue>
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const tl = gsap.timeline({ paused: true })
    const itl = gsap.timeline({ paused: true })
    const images = Array.from(this.$refs["images"].querySelectorAll("image"))
    const d1 = this.$refs["dialogue-1"].$el
    /* must set with gsap since tl is paused */
    gsap.set(d1, { opacity: 0 })
    gsap.set(images, { opacity: 0 })
    tl.to(d1, { opacity: 1 })
    itl.to(images, { opacity: 1, stagger: 1 }, "+=1")
    // itl.to(images, { opacity: 0, stagger: 1 }, `-=${images.length-images.length/2}`)
    this.tl = tl
    this.itl = itl
  },
  methods: {
    enter() {
      this.tl.play()
      this.itl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}

  .d1 p {
    font-size: 1.66em;
  }
</style>
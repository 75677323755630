<template>
<span class="audio-toggle"
      @click="toggleAudio"
      :style="{
        opacity: showAudioControls ? 1 : 0,
        pointerEvents: showAudioControls ? 'all' : 'none',
      }">
  <svg id="speaker" xmlns="http://www.w3.org/2000/svg" width="51.031" height="42.026" viewBox="0 0 51.031 42.026">
    <path :fill="audioActive ? '#e38900' : 'black'" d="M45.178,22.159a1.472,1.472,0,0,0-1.591.18L28.968,34.014H17.5a1.5,1.5,0,0,0-1.5,1.5V50.521a1.5,1.5,0,0,0,1.5,1.5H28.968L43.587,63.7a1.526,1.526,0,0,0,.931.33,1.785,1.785,0,0,0,.66-.15,1.5,1.5,0,0,0,.841-1.351V23.509A1.5,1.5,0,0,0,45.178,22.159ZM43.016,59.4,30.439,49.35a1.527,1.527,0,0,0-.931-.329H19V37.015H29.508a1.534,1.534,0,0,0,.931-.33L43.016,26.631Z" transform="translate(-16 -22.001)" />
    <path :fill="audioActive ? '#e38900' : 'black'" d="M66.279,22.218A1.508,1.508,0,1,0,64.718,24.8C72.433,29.42,76,35.212,76,43.016s-3.572,13.6-11.287,18.217a1.53,1.53,0,0,0-.51,2.071,1.563,1.563,0,0,0,1.291.721,1.444,1.444,0,0,0,.78-.21c8.554-5.132,12.728-11.8,12.728-20.8S74.833,27.35,66.279,22.218Z" transform="translate(-27.976 -22)" />
    <path :fill="audioActive ? '#e38900' : 'black'" d="M64.96,55.99h.54a1.437,1.437,0,0,0,1.111-.51,12.329,12.329,0,0,0,0-16.987,1.5,1.5,0,0,0-1.651-.51,1.53,1.53,0,0,0-.961,1.5V54.49A1.529,1.529,0,0,0,64.96,55.99Z" transform="translate(-27.978 -25.97)" />
  </svg>
</span>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  computed: {
    ...mapState(["audioActive", "showAudioControls"]),
  },
  methods: {
    ...mapActions(['setAudioActive']),
    toggleAudio() {
      this.setAudioActive(!this.audioActive)
    },
  }
}
</script>

<style>
.audio-toggle { position: relative; background: white;
  border-radius: 50%; cursor: pointer; transition: opacity 0.25s ease; }
.audio-toggle svg { display: block; width: 100%; height: 100%; margin: auto; }
</style>
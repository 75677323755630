<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        <image ref="a" x="-80" y="0" :width="1600" :height="1043" :xlink:href="`${publicPath}scenes/postcard/a.jpeg`" />
        <image ref="b" x="1000" y="42" :width="1600" :height="1038" :xlink:href="`${publicPath}scenes/postcard/b.jpg`" />
      </g>
    </svg>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-20.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["a"], { opacity: 0 })
    gsap.set(this.$refs["b"], { opacity: 0 })
    tl.to(this.$refs["a"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["b"], { duration: 1, opacity: 1 }, "+=0.5")
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}
</style>
<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        <foreignObject x="70" y="175" :width="603 * 0.9" :height="829 * 0.9">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/bennett-painting/a.jpeg`" />
          </picture>
        </foreignObject>
        <foreignObject x="675" y="175" :width="603 * 0.925" :height="829 * 0.925">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/bennett-painting/b.png`" />
          </picture>
        </foreignObject>
        <foreignObject x="1300" y="175" :width="603 * 0.9" :height="829 * 0.9">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/bennett-painting/c.png`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    <SceneDialogue
      class="d1 white futura-passata"
			ref="dialogue-1"
      bg="transparent"
			:width="0.95" :tx="0.4" :ty="0.075">
      <p>
        Ray is impressed with the Cobra Head Camp. They enjoy water from a cold, clear
        spring. This landscape inspires even aspiring archaeologist Bennett to create
        paintings when he is not excavating.
      </p>
    </SceneDialogue>
    <SceneDialogue
      class="text-right white caption"
      bg="transparent"
			ref="caption-1"
			:width="0.2" :tx="0.14" :ty="0.91">
      <p>
        Cobra Head Spring,<br>
        John W. Bennett, 1937
      </p>
    </SceneDialogue>
    <SceneDialogue
      class="text-right white caption"
			ref="caption-2"
      bg="transparent"
			:width="0.615" :tx="0.9" :ty="0.933">
      <p>
        Middle: Watercolor of Pinyon Pine in Arizona by John W. Bennett, 1937.<br>
        Right: Photograph of Eastern White Pine in Wisconsin by his son, James P. Bennett, 2021.<br>
        James has lived in Wisconsin for 30 years.
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-10.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["caption-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["caption-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["caption-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["caption-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.entry-title {
		margin-bottom: 0.5em;
	}
  .caption {
    padding: 0;
    p { font-size: 0.9em; }
  }
  .d1 {
    p {
      line-height: 1.33em;
    }
  }
</style>
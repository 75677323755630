<template lang="html">
  <div id="Nav" v-bind:class="{ show }" :style="{bottom, width, left}">
    <svg
      @mouseenter="() => uiOverride(true)"
      @mouseleave="() => uiOverride(false)"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="`0 0 ${totalWidth} 136.5`">
      <defs>
        <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#ffffff"/>
          <stop offset="0.125" stop-color="#f0f0f0"/>
          <stop offset="0.5" stop-color="#69ABBE"/>
          <stop offset="1" stop-color="#69AB9E"/>
        </linearGradient>

        <filter id="dropshadow" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3"/> <!-- stdDeviation is how much to blur -->
          <feOffset dx="0" dy="0" result="offsetblur"/> <!-- how much to offset -->
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.1"/> <!-- slope is the opacity of the shadow -->
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/> <!-- this contains the offset blurred image -->
            <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->
          </feMerge>
        </filter>

        <mask id="timelineMask">
          <rect
            ref="timeline-mask"
            x="0" y="0" :width="timelineWidth" rx="18.5" height="37" fill="white"
            :transform="`translate(0 0)`" />
        </mask>
      </defs>
      <g class="timeline">
        <g transform="translate(0 93)">
          <rect x="0.5" y="0.5" width="1440" height="36" rx="18" fill="rgba(255,255,255,0.2)" stroke="#505050" stroke-width="1" />
          <rect
            mask="url(#timelineMask)"
            width="1441" height="37" rx="18.5" fill="url(#linear-gradient)"/>
          <rect x="0.5" y="0.5" width="1440" height="36" rx="18" fill="none" stroke="#505050" stroke-width="1" />
        </g>
        <g ref="preview" class="bookmark" :transform="`translate(${safeBookmarkPosX} 0)`">
          <rect x="0" y="0" height="78" width="138" stroke="black" stroke-width="2" fill="white" />
          <g v-for="s in sequence">
            <image x="1" y="1" height="76.5" width="136"
                  :opacity="preview === s ? 1 : 0"
                  :href="`${publicPath}scenes/${slide(s).scene}`"
                  :xlink:href="`${publicPath}scenes/${slide(s).scene}`" />
            <text
              :opacity="preview === s ? 1 : 0"
              fill="white"
              stroke="black"
              stroke-width="1.25"
              font-size="18"
              class="futura-passata"
              x="130" y="70"
              text-anchor="end">
                {{slide(s).pn}}
            </text>
          </g>
        </g>
        <g ref="scrub-area" class="scrub-area" transform="translate(0 78)">
          <rect @mousemove="scrubTimeline"
                @touchstart="scrubTouchStart"
                @touchmove="scrubTouchMove"
                @touchend="scrubTouchEnd"
                @click="visitPreview"
                x="0.5" y="0.5" width="1441" height="70" fill="transparent" />
        </g>
      </g>

      <g class="buttons" :transform-origin="`${totalWidth} 0`">
        <g @touchstart="handleNext" @touchend="e => e.stopPropagation()"
           @click="handleNext" class="btn next"
           v-bind:class="{hide: current === sequence.length - 1}"
           :transform="`translate(${totalWidth - 80} 68)`"
           :opacity="current !== sequence.length - 1 ? 1 : 0.2">
          <circle cx="40" cy="40" r="40" fill="white" style="filter:url(#dropshadow)" />
          <g transform="translate(20 25)">
            <path d="M0 0 L30 15 L0 30 L0 0" fill="#e38900"/>
            <path d="M20 0 L50 15 L20 30 L20 0" fill="#e38900"/>
          </g>
        </g>

        <g @touchstart="handlePrev" @touchend="e => e.stopPropagation()"
           @click="handlePrev"
           class="btn prev" v-bind:class="{hide: current === 0}"
           :transform="`translate(${totalWidth - 100} 148) rotate(180)`"
           :opacity="current !== 0 ? 1 : 0.2">
           <circle cx="40" cy="40" r="40" fill="white" style="filter:url(#dropshadow)" />
           <g transform="translate(20 25)">
             <path d="M0 0 L30 15 L0 30 L0 0" fill="#e38900"/>
             <path d="M20 0 L50 15 L20 30 L20 0" fill="#e38900"/>
           </g>
        </g>
      </g>
    </svg>
  </div>

</template>

<script>
import ResizeObserver from "resize-observer-polyfill"
import { mapState } from "vuex"
import { TweenLite } from "gsap"

function smoothstep (min, max, value) {
  var x = Math.max(0, Math.min(1, (value-min)/(max-min)));
  return x*x*(3 - 2*x);
}

function clamp (min, max, value) {
  return Math.max(min, Math.min(max, value))
}

export default {
  data: function() { return {
    publicPath: process.env.BASE_URL,
    bottom: 0,
    width: 0,
    left: 0,
    current: 0,
    progress: 0,
    timelineWidth: 1441,
    totalWidth: 1441 + 200,
    bookmarkPosX: 0,
    bookmarkPreviewWidth: 150,
    ready: false,
    scrub: 0,
    scrubbing: false,
    preview: this.$route.params.slug,
    pointer: true,
    pointerTmt: null,
    pointerTmtLen: 2000,
  } },
  watch: {
    $route: function(to, from) {
      this.seekTimeline()
    },
    show: function(to, from) {
      if (from) { this.resetBookmark() }
      if (from && this.pointerTmt) { window.clearTimeout(this.pointerTmt) }
    },
  },
  computed: {
    ...mapState([
      "sequence"
    ]),
    safeBookmarkPosX: function() {
      return Math.min(
        Math.max(0, this.bookmarkPosX),
        this.timelineWidth - this.bookmarkPreviewWidth * 0.95
      )
    },
  },
  beforeMount() {
    this.deriveCurrentFromSlug()
    // this.resetBookmark()
  },
  mounted() {
    if (this.pointer) {
      this.pointer = false
      this.setShow(true)
      this.pointerTmt = window.setTimeout(() => this.setShow(false), this.pointerTmtLen)
    }

    this.setPosition()
    this.ro = new ResizeObserver((entries, observer) => {
      this.setPosition()
    })
    this.ro.observe(document.body)

    this.resetBookmark()
    this.ready = true

    this.seekTimeline()

    window.addEventListener("keydown", this.handleKeydown)
  },
  beforeDestroy() {
    this.ro.unobserve(document.body)
    window.removeEventListener("keydown", this.handleKeydown)
  },
  methods: {
    slide(key) { return this.$store.getters.slide(key) },
    prev() {
      const { sequence, current, $router, resetBookmark } = this
      const to = current - 1
      if (to < 0) { return }
      $router.push(sequence[to])
      resetBookmark()
    },
    next() {
      const { sequence, current, $router, resetBookmark } = this
      const to = current + 1
      if (to === sequence.length) { return }
      $router.push(sequence[to])
      resetBookmark()
    },
    handlePrev(e) {
      e.preventDefault()
      e.stopPropagation()
      this.prev()
    },
    handleNext(e) {
      e.preventDefault()
      e.stopPropagation()
      this.next()
    },
    seekTimeline() {
      const curr = this.deriveCurrentFromSlug()
      if (this.$refs["timeline-mask"]) {
        let offset = this.getPositionAtIndex(curr)
        if (curr === this.sequence.length - 1) {offset = this.timelineWidth}
        TweenLite.to(this.$refs["timeline-mask"], 0.5, {
          x: -this.timelineWidth + offset
        })
        this.resetBookmark()
      }
    },
    deriveCurrentFromSlug() {
      const slug = this.$route.params.slug
      const ci = this.sequence.findIndex(s => s === slug)
      this.current = ci
      this.progress = Math.max(ci / (this.sequence.length - 1), 0.05)
      return ci
    },
    setPosition() {
      const { width, height, x, y, left, top } = document.getElementById("Frame").getBoundingClientRect()
      // NOTE: frame["top"/"left"] account for edge
      this.bottom = `${(y||top) + width * 0.025}px`
      this.width = `${width * 0.95}px`
      this.left = `${(x||left) + width * 0.025}px`
    },
    scrubTimeline(e) {
      const { clientX, target } = e
      const { x, left, width } = target.getBoundingClientRect()
      // NOTE: target["left"] account for edge
      // create < 0, > 1 boundaries
      const raw = (clientX - (x||left)) / width
      const clamped = Math.min(Math.max(0, raw), 1)
      this.bookmarkPosX = clamped * this.timelineWidth - this.bookmarkPreviewWidth / 2

      const interval = 1 / (this.sequence.length - 1)
      const closest = Math.round(clamped / interval)*interval
      const lookupScene = Math.round(closest * (this.sequence.length - 1))
      if (this.sequence[lookupScene] !== this.preview) {
        this.preview = this.sequence[lookupScene]
      }
    },
    scrubTouchStart(e) {
      this.setShow(true)
    },
    scrubTouchMove(e) {
      if (!this.show) { this.setShow(true) }
      this.uiOverride(true)
      this.scrubTimeline({target: e.target, clientX: e.targetTouches[0].clientX})
    },
    scrubTouchEnd(e) {
      e.preventDefault()
      this.uiOverride(false)
      this.setShow(false)
      this.visitPreview()
    },
    placeMarker(i) {
      return this.getPositionAtIndex(i)
    },
    getPositionAtIndex(i) {
      const pct = i / (this.sequence.length - 1)
      return pct * this.timelineWidth
    },
    visitPreview() {
      if (this.preview !== this.$route.params.slug) {
        this.$router.push(`/${this.preview}`)
      }
    },
    resetBookmark() {
      const currIdx = this.sequence.findIndex(s => s == this.$route.params.slug)
      this.bookmarkPosX = this.placeMarker(currIdx) - this.bookmarkPreviewWidth / 2
      this.preview = this.$route.params.slug
    },
    handleKeydown(e) {
      switch (e.keyCode) {
        case 39:
          this.next()
          break
        case 37:
          this.prev()
          break
        default:
          break
      }
    }
  },
  props: [
    "show",
    "setShow",
    "uiOverride",
  ],
}
</script>

<style lang="scss">
#Nav {
  position: absolute; opacity: 0; transition: opacity 0.25s ease;
  svg { overflow: visible; width: 100%; position: absolute; bottom: 0; }
  &.show { opacity: 1; }
  g.btn { cursor: pointer; &.hide { cursor: default; } }
  .scrub-area { cursor: pointer; }
}

@media screen and (max-width: 480px) {
  #Nav {
    display: none;
  }
}
</style>

<template lang="html">
  <span :data-label="label" class="rad-button rockwell">{{label}}</span>
</template>

<script>
export default {
  props: ['label']
}
</script>

<style lang="scss">
  $b-w: 0.1em;
  .rad-button {
    padding: 0.5em 0.8em 0.55em;
    cursor: pointer;
    position: relative; box-sizing: padding-box; display: inline-block;
    color: black; line-height: normal; z-index: 0; border: $b-w solid black;
    &:before {
      content: ""; position: absolute; top: -0.1em; left: -0.1em; background: #E38900;
      width: calc(100% + 0.1em); height: 100%; border-radius: inherit;
      border: $b-w solid black;
      // height: calc(100% - #{$b-w/2}); left: $b-w/2;
    }
    &:after {
      content: attr(data-label); position: absolute; top: 50%; left: 0;
      color: white; width: 100%;  transform: translateY(-50%);
      text-align: center; border-radius: inherit;
      // height: calc(100% - #{$b-w/2}); left: $b-w/2;
    }
    &:before, &:after {
      transition: clip-path 0.3s ease;
      clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    }
    &:hover {
      &:before, &:after { clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); }
    }
  }
</style>

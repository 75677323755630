<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>
      <clipPath id="clip-outline">
        <use ref="clip-outline" xlink:href="#Frame"/>
      </clipPath>
      <clipPath id="clip-detail">
        <use ref="clip-detail" xlink:href="#Frame"/>
      </clipPath>
      <clipPath id="clip-outline-strokes" ref="clip-outline-strokes">
        <rect x="88.12" y="142.46" width="1658.92" height="197.26" transform="translate(-17.78 80.57) rotate(-5.08)" />
        <rect x="50.72" y="372.29" width="1739.03" height="197.26" transform="translate(1890.27 826.47) rotate(173.11)" />
        <rect x="112.4" y="712.53" width="1658.92" height="197.26" transform="translate(30.06 -35.29) rotate(2.08)" />
        <rect x="53.18" y="562.04" width="1812.25" height="197.26" transform="translate(-54.75 85.91) rotate(-5.08)" />
        <rect x="88.12" y="345.73" width="1658.92" height="197.26" transform="translate(36.39 -70.05) rotate(4.36)" />
        <rect x="174.91" y="733.03" width="1721.5" height="197.26" transform="translate(-69.59 93.34) rotate(-5.08)" />
        <rect x="13.23" y="218.19" width="1903.24" height="197.26" transform="translate(12.11 -36.45) rotate(2.08)" />
        <rect x="3.5" y="848.01" width="1912.58" height="196.76" transform="translate(34.99 -35.85) rotate(2.08)" />
        <rect x="57.31" y="0.5" width="1862.65" height="197.26" />
      </clipPath>
      <clipPath id="clip-detail-strokes" ref="clip-detail-strokes">
        <rect x="88.12" y="142.46" width="1658.92" height="197.26" transform="translate(-17.78 80.57) rotate(-5.08)" />
        <rect x="50.72" y="372.29" width="1739.03" height="197.26" transform="translate(1890.27 826.47) rotate(173.11)" />
        <rect x="112.4" y="712.53" width="1658.92" height="197.26" transform="translate(30.06 -35.29) rotate(2.08)" />
        <rect x="53.18" y="562.04" width="1812.25" height="197.26" transform="translate(-54.75 85.91) rotate(-5.08)" />
        <rect x="88.12" y="345.73" width="1658.92" height="197.26" transform="translate(36.39 -70.05) rotate(4.36)" />
        <rect x="174.91" y="733.03" width="1721.5" height="197.26" transform="translate(-69.59 93.34) rotate(-5.08)" />
        <rect x="13.23" y="218.19" width="1903.24" height="197.26" transform="translate(12.11 -36.45) rotate(2.08)" />
        <rect x="3.5" y="848.01" width="1912.58" height="196.76" transform="translate(34.99 -35.85) rotate(2.08)" />
        <rect x="57.31" y="0.5" width="1862.65" height="197.26" />
      </clipPath>


      <g clip-path="url(#clip-frame)">
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}bg-texture.jpg`" />
          </picture>
        </foreignObject>

        <image clip-path="url(#clip-outline-strokes)" x="0" y="0" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/the-end/bg-outline.png`" />
        <image clip-path="url(#clip-detail-strokes)" x="0" y="0" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/the-end/bg-detail.png`" />
        <image clip-path="url(#clip-outline-strokes)" x="0" y="0" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/the-end/mg-outline.png`" />
        <image clip-path="url(#clip-detail-strokes)" x="0" y="0" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/the-end/mg-detail.png`" />
        <image clip-path="url(#clip-outline-strokes)" x="0" y="0" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/the-end/fg-outline.png`" />
        <image clip-path="url(#clip-detail-strokes)" x="0" y="0" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/the-end/fg-detail.png`" />
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="futura-passata d1"
      bg="transparent"
			:width="0.3" :tx="0.025" :ty="0.1">
      <h1>THE END</h1>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="d2"
      bg="transparent"
			:width="0.75" :tx="0.95" :ty="0.133">
      <p class="quote">
        <em>
          “This is indeed the West of my dreams…”
        </em>
      </p>
      <p class="attribution">
      — Ray Garner
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-25.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    const clipOutlineStrokes = this.$refs["clip-outline-strokes"].querySelectorAll("rect")
    const clipDetailStrokes = this.$refs["clip-detail-strokes"].querySelectorAll("rect")
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    gsap.set(clipOutlineStrokes, { transformOrigin: "left center", scaleX: 0 })
    gsap.set(clipDetailStrokes, { transformOrigin: "left center", scaleX: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(clipOutlineStrokes, { duration: 2, scaleX: 1, stagger: 0.2 })
    tl.to(clipDetailStrokes, { duration: 2, scaleX: 1, stagger: 0.2 }, "-=1")
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.entry-title {
		margin-bottom: 0.5em;
	}
  .big {
    font-size: 1.5em;
  }

  .d1 {
    h1 {
      font-size: 3.5em;
    }
  }

  .d2 {
    p {
      margin: 0 auto;
      text-align: right;
    }
    .quote {
      font-size: 1.5em;
    }
    .attribution {
      font-size: 1.25em;
      margin: 0.25em 0.5em 0 0;
    }
  }
</style>
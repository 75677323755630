<template lang="html">
  <div :class="[className, { content }]"
       class="content"
       :style="{
         width: w, height: h, top, left, fontSize: finalFontSize,
         transform: `translateY(-50%)`, backgroundColor: bg,
         ...styles,
        }">
       <slot></slot>
  </div>
</template>

<script>
import ResizeObserver from "resize-observer-polyfill"
export default {
  data: () => ({
    top: "0px",
    left: "0px",
    w: "0px",
    h: "auto",
    finalFontSize: "16px",
    ro: null,
  }),
  watch: {
    fixedHeight() { this.computeBoundingRect() },
    width() { this.computeBoundingRect() },
    tx() { this.computeBoundingRect() },
    ty() { this.computeBoundingRect() },
  },
  mounted() {
    this.computeBoundingRect()
    this.ro = new ResizeObserver((entries, observer) => {
      this.computeBoundingRect()
    })
    this.ro.observe(document.body)
  },
  beforeDestroy() {
    this.ro.unobserve(document.body)
  },
  methods: {
    computeBoundingRect() {
      const { width, height, x, y, left, top } = document.getElementById("Frame").getBoundingClientRect()
      const finTop = (y||top) + this.ty * height
      const finLeft = (x||left) + this.tx * (width - width*this.width)
      const rounded = Math.round((width/60*this.fontSize/16+0.00001)*100)/100
      this.finalFontSize = `${rounded}px`
      this.top = `${finTop}px`
      this.left = `${finLeft}px`
      this.w = `${width*this.width}px`
      if (this.fixedHeight) {
        this.h = `${this.fixedHeight * height}px`
      }
    },
  },
  props: {
    content: String,
    show: Boolean,
    width: Number,
    fixedHeight: Number,
    tx: Number,
    ty: Number,
    bg: {
      type: String,
      default: "rgba(255,255,255,0.9)",
    },
    fontSize: {
      type: Number,
      default: 18,
    },
    styles: Object,
    className: {
      type: String,
      default: ""
    },
  },
}
</script>

<style lang="css" scoped>
  .content {
    position: absolute;
    padding: 0.66em;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    /* -webkit-font-smoothing: none; */
    /* font-smooth: none; */
  }
</style>

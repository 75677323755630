<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        <image ref="bg" x="0" y="0" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}bg-texture.jpg`" />
        <image ref="map" x="-275" y="0" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/double-rainbow/map.png`" />
        <image ref="scan-a" transform="scale(.75)" x="-25" y="975" :width="607" :height="420" :xlink:href="`${publicPath}scenes/double-rainbow/a.jpeg`" />
        <image ref="scan-b" transform="scale(0.6)" x="2200" y="50" :width="932" :height="1624" :xlink:href="`${publicPath}scenes/double-rainbow/b.png`" />
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 narrative fancy-absolute"
      bg="transparent"
			:width="0.62" :tx="0.033" :ty="0.133">
      <p>
        They set out with Harry Goulding in search of the Double Rainbow Bridge. They hire
        a young Navajo guide, “the-lefthanded-man’s-boy-with-moustache,-his son”, whose
        family has resided in the area for many years. They pass an ancient dwelling near
        the family’s home.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="d2 narrative"
      bg="transparent"
			:width="0.5" :tx="0.4" :ty="0.88">
      <p>
        An experienced climber, Ray ascends the bridge. He is excited to learn that they
        are the first visiting party of men to be guided to it. The first party that Harry
        Goulding ever brought was three women!
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-15.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["bg"], { opacity: 0 })
    gsap.set(this.$refs["map"], { opacity: 0 })
    gsap.set(this.$refs["scan-a"], { opacity: 0 })
    gsap.set(this.$refs["scan-b"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["bg"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["map"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["scan-a"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["scan-b"], { duration: 1, opacity: 1 }, "+=0.5")
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}
  .d1 p { margin: 0.25em auto; }
</style>
<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}bg-texture.jpg`" />
        </picture>
      </foreignObject>

      <g clip-path="url(#clip-frame)">
        <foreignObject x="-25" y="250" :width="607* 2" :height="420 * 2">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/gouldings/a.jpeg`" />
          </picture>
        </foreignObject>

        <foreignObject x="1325" y="50" :width="607*0.85" :height="705*0.85">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/gouldings/b.jpeg`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 narrative"
      bg="transparent"
			:width="0.65" :tx="0.033" :ty="0.125">
      <p>
        Ray, Martin Gambee, and Edward Beckwith visit Goulding’s Trading Post. Ray meets
        the owners, John Goulding and his wife and business partner, who goes by ‘Mike’.
        John left quite an impression on Ray... 
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      bg="transparent"
			:width="0.33" :tx="0.95" :ty="0.8">
			<em>
        “Quite a character. Looks like he just stepped out of the Old West.… Talks
        cleverly in the picturesque lingo of the West. Appreciates the beauty of his
        surroundings. A fine fellow. … What a world of difference between his life and
        that of a ‘successful’ N.Y. business man.” 
			</em>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-14.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}
</style>
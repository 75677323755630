<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="futura-passata white d1"
      bg="transparent"
			:width="0.8" :tx="0.24" :ty="0.1">
      <h1>THANK YOU / CREDITS</h1>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="white d2"
      bg="transparent"
			:width="0.9" :tx="0.5" :ty="0.55">
			<p>
        Like nearly all of the content that The ONWARD Project creates and works with,
        this graphic narrative takes place entirely on Navajo, Hopi, and San Juan Southern
        Paiute lands. We thank these communities, past and present. 
        <br><br>
        We also extend a huge thank you to: Lithuania Denetso, Larry Denetso, Charles
        Littlesalt, Jeanne Littlesalt, Julia Addakai, John Bennett, Jim Bennett, Gay Garner Mackintosh,
        The Board of Directors of The ONWARD Project for their support and The ONWARD
        Project community who donated funds to create this project. The artists who
        brought this graphic narrative to life: Abel Nash, Charles Littlesalt, John
        Bennett and Jim Bennett.
        <br><br>
        A special thank you to our web developer Ben Benjamin and illustrator Radha
        Vishnubhotla who enabled us to bring this project to you. As well as Madi Fair
        and Allison Fischer-Olson who produced and organized this project.
      </p>
    </SceneDialogue>
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const tl = gsap.timeline({ paused: true })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
.d2 p {
  font-size: 1.33em;
}
</style>
<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
    </svg>
    <SceneDialogue
      class="white"
      bg="transparent"
			:width="0.9" :tx="0.5" :ty="0.5">
      <h1 ref="dialogue-1" class="futura-passata white d1">Notes and photography captions</h1>
      <div ref="dialogue-2">
        <p>
          A note about location names: Because this story is from the perspective of Ray
          Garner, a non-Native man, The ONWARD Project chose to use English place names,
          rather than the Diné names. The places visited in this story have been known by
          their Diné names for far longer than the English ones. 
        </p>
        <p>
          A disclaimer about use of paintings by Martin Gambee: Every effort has been
          made to contact copyright holders over the works used in this publication.
          Please contact the publisher, The ONWARD Project, if you are the rights holder
          or have information about the rights of the works reproduced in this book. 
        </p>
        <h3 class="futura-passata">
          Pages Notes 
        </h3>
        <p class="sm">
          Page 1: Art by Charlie Littlesalt.<br>
          Page 2: Page from journal of Ray Garner, used by permission of the descendants of Ray Garner. <br>
          Page 4: “Agathla,” (1938) painting by Martin Gambee. http://www.billstrain.com/gambee.htm, accessed 9/2021. Photograph by Ray Garner, used by permission of the descendants of Ray Garner. Agathla is the anglicized spelling of its Navajo name, Aghaałą́, which translates to“Much Wool” or “Much Fur.” Some sources state that the name “Much Fur” refers to the custom of scraping deer hides on the coarse basalt that eventually piled up the hair or “wool” along the sides. This prominent feature is also known as El Capitan. <br>
          Page 5: “Chicken Pull” (1938) and “Busy Day” (1937), by Martin Gambee. http://www.billstrain.com/gambee.htm, accessed 9/2021.<br>
          Note: A chicken pull is a competitive game in which riders on horseback take turns pulling a chicken that is buried neck-deep out of the ground and attempt to complete a lap around the arena without having it stolen by other riders. For ethical reasons, chicken pulls played today do not use live chickens. <br>
          Page 6: Images at left and center from Winkler RBMVE Film Collection, The ONWARD Project. Photograph at right by Ray Garner, used by permission of the descendants of Ray Garner. Oljeto is the spelling that Ray uses, but it is alternatively known today as Oljato.<br>
          Page 7: “Rain on the Reservation,” (1937) painting by Martin Gambee. http://www.billstrain.com/gambee.htm, accessed 9/2021. Photograph by Ray Garner, used by permission of the descendants of Ray Garner. <br>
          Page 8: Film footage from Winkler RBMVE Film Collection, The ONWARD Project. Note: Garner misspells Hanson’s name as “Hansen” in his journal.<br>
        </p>
      </div>
    </SceneDialogue>
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const tl = gsap.timeline({ paused: true })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["dialogue-1"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"], { opacity: 0 })
    tl.to(this.$refs["dialogue-1"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"], { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
.sm {
	font-size: 0.85em;
}

.content {
  overflow: scroll;
  height: -webkit-fill-available!important;
}
</style>
<template lang="html">
  <div id="NavMobile" v-bind:class="{ show }">
    <svg class="timeline"
      @mouseenter="() => uiOverride(true)"
      @mouseleave="() => uiOverride(false)"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="`0 0 ${totalWidth} 250`">
      <defs>
        <linearGradient id="linear-gradient--mobile" x2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#69AB9E"/>
          <stop offset="0.502" stop-color="#f0f0f0"/>
          <stop offset="1" stop-color="#69AB9E"/>
        </linearGradient>

        <mask id="timelineMask--mobile">
          <rect
            ref="timeline-mask"
            x="0" y="0" :width="timelineWidth" rx="50" height="100" fill="white"
            :transform="`translate(0 0)`" />
        </mask>
      </defs>
      <g class="timeline">
        <g transform="translate(0 165)">
          <rect x="0.5" y="0.5" width="1440" height="100" rx="50" fill="rgba(255,255,255,0.2)" stroke="#505050" stroke-width="1" />
          <rect
            mask="url(#timelineMask--mobile)"
            width="1441" height="100" rx="50" fill="url(#linear-gradient--mobile)"/>
          <rect x="0.5" y="0.5" width="1440" height="100" rx="50" fill="none" stroke="#505050" stroke-width="1" />
        </g>
        <g ref="preview" class="bookmark" :transform="`translate(${safeBookmarkPosX} 0)`">
          <rect x="0" y="0" height="150" width="207" stroke="black" stroke-width="2" fill="transparent" />
          <g v-for="s in sequence">
            <image x="1" y="1" height="148" width="205"
                  :opacity="preview === s ? 1 : 0"
                  :href="`${publicPath}scenes/${slide(s).scene}`"
                  :xlink:href="`${publicPath}scenes/${slide(s).scene}`" />
              <text
                :opacity="preview === s ? 1 : 0"
                fill="white"
                stroke="black"
                stroke-width="1.5"
                font-size="35"
                class="futura-passata"
                x="195" y="135"
                text-anchor="end">
                  {{slide(s).pn}}
              </text>
          </g>
        </g>
        <g ref="scrub-area" class="scrub-area" transform="translate(0 0)">
          <rect @mousemove="scrubTimeline"
                @touchstart="scrubTouchStart"
                @touchmove="scrubTouchMove"
                @touchend="scrubTouchEnd"
                @click="visitPreview"
                x="0.5" y="0.5" width="1441" height="250" fill="transparent" />
        </g>
      </g>
    </svg>
    <div class="buttons">
      <span class="button"
        @click="handlePrev"
        @touchstart="handlePrev" @touchend="e => e.stopPropagation()"
        v-bind:class="{hide: current === 0}"
        :style="{opacity: current !== 0 ? 1 : 0.2}">
        <svg width="100" height="100" viewBox="0 0 100 100"
          :style="{transform: `rotate(180deg)`, transformOrigin: `center`,}">
          <g transform-origin="50 50">
            <circle cx="50" cy="50" r="50" fill="white" />
            <g transform-origin="50 50" transform="translate(30 35)">
              <path d="M0 0 L30 15 L0 30 L0 0" fill="#e38900"/>
              <path d="M20 0 L50 15 L20 30 L20 0" fill="#e38900"/>
            </g>
          </g>
        </svg>
      </span>
      <span class="button"
        @click="handleNext"
        @touchstart="handleNext" @touchend="e => e.stopPropagation()"
        :transform="`translate(${totalWidth - 80} 90)`"
        v-bind:class="{hide: current === sequence.length - 1}"
        :style="{opacity: current !== sequence.length - 1 ? 1 : 0.2}">
        <svg width="100" height="100" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="50" fill="white" />
          <g transform="translate(30 35)">
            <path d="M0 0 L30 15 L0 30 L0 0" fill="#e38900"/>
            <path d="M20 0 L50 15 L20 30 L20 0" fill="#e38900"/>
          </g>
        </svg>
      </span>
    </div>
  </div>

</template>

<script>
import { mapState } from "vuex"
import { TweenLite } from "gsap"

export default {
  data: function() { return {
    publicPath: process.env.BASE_URL,
    bottom: 0,
    width: "100%",
    left: 0,
    current: 0,
    progress: 0,
    timelineWidth: 1441,
    totalWidth: 1441,
    bookmarkPosX: 0,
    bookmarkPreviewWidth: 200,
    ready: false,
    scrub: 0,
    scrubbing: false,
    preview: this.$route.params.slug,
    pointer: true,
    pointerTmt: null,
    pointerTmtLen: 2000,
  } },
  watch: {
    $route: function(to, from) {
      this.seekTimeline()
    },
    show: function(to, from) {
      if (from) { this.resetBookmark() }
      if (from && this.pointerTmt) { window.clearTimeout(this.pointerTmt) }
    },
  },
  computed: {
    ...mapState([
      "sequence"
    ]),
    safeBookmarkPosX: function() {
      return Math.min(
        Math.max(0, this.bookmarkPosX),
        this.timelineWidth - this.bookmarkPreviewWidth * 0.95
      )
    },
  },
  beforeMount() {
    this.deriveCurrentFromSlug()
    // this.resetBookmark()
  },
  mounted() {
    if (this.pointer) {
      this.pointer = false
      this.setShow(true)
      this.pointerTmt = window.setTimeout(() => this.setShow(false), this.pointerTmtLen)
    }

    this.resetBookmark()
    this.ready = true

    this.seekTimeline()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setPosition)
  },
  methods: {
    slide(key) { return this.$store.getters.slide(key) },
    prev() {
      const { sequence, current, $router, resetBookmark } = this
      const to = current - 1
      if (to < 0) { return }
      $router.push(sequence[to])
      resetBookmark()
    },
    next() {
      const { sequence, current, $router, resetBookmark } = this
      const to = current + 1
      if (to === sequence.length) { return }
      $router.push(sequence[to])
      resetBookmark()
    },
    handlePrev(e) {
      e.preventDefault()
      e.stopPropagation()
      this.prev()
    },
    handleNext(e) {
      e.preventDefault()
      e.stopPropagation()
      this.next()
    },
    seekTimeline() {
      const curr = this.deriveCurrentFromSlug()
      if (this.$refs["timeline-mask"]) {
        let offset = this.getPositionAtIndex(curr)
        if (curr === this.sequence.length - 1) {offset = this.timelineWidth}
        TweenLite.to(this.$refs["timeline-mask"], 0.5, {
          x: -this.timelineWidth + offset
        })
        this.resetBookmark()
      }
    },
    deriveCurrentFromSlug() {
      const slug = this.$route.params.slug
      const ci = this.sequence.findIndex(s => s === slug)
      this.current = ci
      this.progress = Math.max(ci / (this.sequence.length - 1), 0.05)
      return ci
    },
    setPosition() {
      const { width, height, x, y, left, top } = document.getElementById("Frame").getBoundingClientRect()
      // NOTE: frame["top"/"left"] account for edge
      this.bottom = `${(y||top) + width * 0.025}px`
      this.width = `${width * 0.95}px`
      this.left = `${(x||left) + width * 0.025}px`
    },
    scrubTimeline(e) {
      const { clientX, target } = e
      const { x, left, width } = target.getBoundingClientRect()
      // NOTE: target["left"] account for edge
      // create < 0, > 1 boundaries
      const raw = (clientX - (x||left)) / width
      const clamped = Math.min(Math.max(0, raw), 1)
      this.bookmarkPosX = clamped * this.timelineWidth - this.bookmarkPreviewWidth / 2

      const interval = 1 / (this.sequence.length - 1)
      const closest = Math.round(clamped / interval)*interval
      const lookupScene = Math.round(closest * (this.sequence.length - 1))
      if (this.sequence[lookupScene] !== this.preview) {
        this.preview = this.sequence[lookupScene]
      }
    },
    scrubTouchStart(e) {
      this.setShow(true)
      // this.uiOverride(true)
      // this.scrubTimeline({target: e.target, clientX: e.targetTouches[0].clientX})
    },
    scrubTouchMove(e) {
      if (!this.show) { this.setShow(true) }
      this.uiOverride(true)
      this.scrubTimeline({target: e.target, clientX: e.targetTouches[0].clientX})
    },
    scrubTouchEnd(e) {
      e.preventDefault()
      this.uiOverride(false)
      this.setShow(false)
      this.visitPreview()
    },
    placeMarker(i) {
      return this.getPositionAtIndex(i)
    },
    getPositionAtIndex(i) {
      const pct = i / (this.sequence.length - 1)
      return pct * this.timelineWidth
    },
    visitPreview() {
      if (this.preview !== this.$route.params.slug) {
        this.$router.push(`/${this.preview}`)
      }
    },
    resetBookmark() {
      const currIdx = this.sequence.findIndex(s => s == this.$route.params.slug)
      this.bookmarkPosX = this.placeMarker(currIdx) - this.bookmarkPreviewWidth / 2
      this.preview = this.$route.params.slug
    },
  },
  props: [
    "show",
    "setShow",
    "uiOverride",
  ],
}
</script>

<style lang="scss">
#NavMobile {
  display: none; width: 96%; left: 2%;
  position: fixed; bottom: 1em; opacity: 0; transition: opacity 0.25s ease;
  .buttons { text-align: right; margin-top: 1em; }
  .button { display: inline-block; width: 3em; height: 3em; vertical-align: middle;
    cursor: pointer; &.hide { cursor: default; }
    &:first-of-type { margin-right: 1em; }
    svg { width: 100%; height: 100%; } }
  svg.timeline { overflow: visible; width: 100%; }
  &.show { opacity: 1; }
  .scrub-area { cursor: pointer; }
}

@media screen and (max-width: 480px) {
  #NavMobile {
    display: block;
  }
}
</style>

<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        
        <foreignObject x="50" y="350" :width="724" :height="530">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/totem-pole/a.jpg`" />
          </picture>
        </foreignObject>
        
        <foreignObject x="575" y="250" :width="725" :height="532">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/totem-pole/b.jpg`" />
          </picture>
        </foreignObject>
        
        <foreignObject x="1280" y="25" :width="1268/2" :height="1600/2">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/totem-pole/totem-pole.jpg`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 futura-passata white"
      bg="transparent"
			:width="0.633" :tx="0.033" :ty="0.1">
        <p>
          From Oljeto, Ray and Martin Gambee head to Monument Valley to visit the
          Expedition’s biology crew who are collecting plant samples. They make their
          camp near a monument called the Totem Pole. But by the time they get there
          the sky is too dark to take any film footage.
        </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="d2 white"
      bg="transparent"
			:width="0.45" :tx="0.8" :ty="0.85">
			<h2 class="entry-title futura-passata">July 5, 1937</h2>
			<em>
        “The Monuments were simply gorgeous. Half in light, half in shadow, they
        presented an unbelievable spectacle.” 
			</em>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-6.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.entry-title {
		margin-bottom: 0.5em;
	}
  .d1 {
    p {
      line-height: 1.25em;
    }
  }
</style>
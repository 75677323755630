<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" fill="white" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-start/bg-color.jpg`" />
        </picture>
      </foreignObject>

      <g class="outline">
        <foreignObject ref="outline-1" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-start/plant-1.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-2" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-start/plant-2.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-3" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-start/plant-3.png`" />
          </picture>
        </foreignObject>
      </g>
      
      <g class="outline">
        <foreignObject ref="outline-1" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-start/man-1.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-2" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-start/man-2.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-3" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-start/man-3.png`" />
          </picture>
        </foreignObject>
      </g>
      
      <image ref="sun" x="777" y="101" :width="247" :height="224" :xlink:href="`${publicPath}scenes/darkroom-start/sun.png`" />
    </svg>

    <SceneDialogue
      class="d1"
      ref="dialogue-1"
      bg="transparent"
      :width="0.425" :tx="0.95" :ty="0.2">
        <h2>
          <span class="title opti-morgan">Marsh Pass, AZ</span><br>
          <span class="sub futura-passata">July 1, 1937</span>
        </h2>
    </SceneDialogue>
    <SceneDialogue
      class="d2 fancy-absolute"
      ref="dialogue-2"
      bg="rgba(255,255,255,0.9)"
      :width="0.3" :tx="0.04" :ty="0.605">
      <p>
        <strong class="lg max-name">Max Littlesalt</strong>works to dig a hole
        for a new darkroom at the Rainbow Bridge-Monument Valley Expedition
        camp in Marsh Pass, AZ. He starts in the early morning, before it
        gets too hot.
      </p>
    </SceneDialogue>
    <SceneDialogue
      class="d3 fancy-absolute"
      ref="dialogue-3"
      bg="rgba(255,255,255,0.95)"
      :width="0.22" :tx="0.95" :ty="0.55">
      <p>
        He can hear the men of the Expedition begin to wake up.
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-1.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["sun"], { opacity: 0, y: 10 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-3"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["sun"], { duration: 1, opacity: 1, y: 0 }, 0)
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-3"].$el, { duration: 1, opacity: 1 }, "+=0.5")
    this.tl = tl

  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
.title {
  text-align: center;
  font-size: 1.25em;
}

.max-name {
  margin-right: 0.2em;
}

.lg {
  font-size: 1.1em;
}

.sm {
  font-size: 0.95em;
}

.d1 {
  .title {
    font-size: 1.2em;
    line-height: 1.1em;
  }
  .sub {
    font-size: 1.1em;
  }
}

.d2 {
  padding: 0.66em;
  line-height: 1.25em;
  p { margin: 0; }
}

.d3 {
  padding: 0.5em;
  p {
    // font-size: 1.15em;
    margin: 0;
  }

}

.d2 {
  box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.15);
}

.d3 {
  box-shadow: 0px 0px 1.5em 0px rgba(0,0,0,0.33);
}

$anim-length: 0.6s;

.outline foreignObject {
  animation-name: grass; animation-iteration-count: infinite;
  animation-timing-function: steps(1,end); animation-duration: #{$anim-length};
  &:nth-of-type(1) { animation-delay: 0s; }
  &:nth-of-type(2) { animation-delay: -#{$anim-length * .34}; }
  &:nth-of-type(3) { animation-delay: -#{($anim-length * .66) }; }
}

@keyframes grass {
  0% { opacity: 1; }
  33% { opacity: 0; }
  100% { opacity: 0; }
}
</style>
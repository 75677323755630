<template>
  <div ref="slide" class="slide">
    <svg ref="scene"></svg>
  </div>
</template>

<script>
import gsap from "gsap"
import { mapActions } from "vuex"
import SceneDialogue from "../SceneDialogue.vue"
import SceneAudio from "../SceneAudio.vue"
import ImageLazy from "../ImageLazy.vue"
import { onMounted } from 'vue'

function computeViewboxCoords(globalDims, viewboxDims, userXY) {
  // converts global coords to local
  const { width: globalWidth, height: globalHeight, x: globalX, y: globalY } = globalDims
  const { width: viewWidth, height: viewHeight } = viewboxDims
  const { x: userX, y: userY } = userXY
  const normX = userX - globalX
  const viewX = viewWidth * normX / globalWidth
  const normY = userY - globalY
  const viewY = viewHeight * normY / globalHeight
  return { x: viewX, y: viewY }
}

export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
    canvasWidth: 1920,
    canvasHeight: 1080,
  }),
  props: ["slide"],
  beforeUnmount() {
    this.audioReset()
  },
  mounted() {
    const imgs = [
      Array.from(this.$refs["slide"].querySelectorAll("image")).map(image => image.getAttribute("xlink:href")),
      Array.from(this.$refs["slide"].querySelectorAll("img")).map(img => (img.currentSrc || img)),
    ].flat()
    
    Promise.all(
      imgs.map(img => {
        return new Promise((resolve, reject) => {
          if (typeof img === "object") {
            img.onload = () => resolve(`loaded... ${src}`)
            img.onerror = () => resolve(`errored... ${src}`)
            return
          }
          const src = img
          const test = new Image()
          test.width = 0
          test.height = 0
          test.onload = () => resolve(`loaded... ${src}`)
          test.onerror = () => resolve(`errored... ${src}`)
          test.src = src
        })
      })
    ).then(s => {
      this.enter()
      this.setLoader(false)
    })
  },
  methods: {
    ...mapActions([
      "setLoader", "setShowAudioControls", "setAudioSrc", "audioPlay", "audioReset",
    ]),
    loaded() {console.log("i loaded")},
    enter() {console.log("enter from base")},
    exit() {console.log("exit from base")},
    findCoords: function(e) {
      const frame = this.$refs["frame"]
      const frameDims = frame.getBoundingClientRect()
      const viewbox = {
        width: parseFloat(frame.getAttribute("width")),
        height: parseFloat(frame.getAttribute("height")),
      }
      const user = {
        x: e.clientX,
        y: e.clientY,
      }
      console.log(computeViewboxCoords(frameDims, viewbox, user))
    }
  },
  components: {
    SceneDialogue,
    SceneAudio,
    ImageLazy,
  },
}
</script>

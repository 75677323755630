<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        
        <image x="-180" ref="painting" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/first-expedition/painted.jpg`" />

        <image ref="photo" x="1350" y="100" :width="607*0.9" :height="705*0.9" :xlink:href="`${publicPath}scenes/first-expedition/photo.jpg`" />
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 futura-passata white"
      bg="transparent"
			:width="0.73" :tx="0.01" :ty="0.1">
      <p>
        Ray accompanies Martin Gambee on his first excursion. They stop along the way so
        Ray can shoot film footage and take photographs, while Martin paints the scenery
        and landmarks.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="d2"
			:width="0.33" :tx="0.95" :ty="0.8">
			<h2 class="entry-title futura-passata">July 3, 1937</h2>
			<em>
        “Left Marsh Pass at about 3:00pm, after drawing provisions from the store house
        for a 8 to 10 day’s [sic] trip. We’re going to El Capitan, Kayenta, Oljato, Monument
        Valley, Gouldings Post and Mexican Hat.”
			</em>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-4.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    // gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    gsap.set(this.$refs["painting"], { opacity: 0 })
    gsap.set(this.$refs["photo"], { opacity: 0, y: -10 })
    // tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["painting"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["photo"], { duration: 1, opacity: 1, y: 0 }, "-=1")
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.entry-title {
		margin-bottom: 0.5em;
	}
  .d1 {
    p {
      font-size: 1.1em;
      line-height: 1.25em;
      -webkit-text-stroke-width: 0.025em;
      -webkit-text-stroke-color: black;
    }
  }
</style>
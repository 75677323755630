<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g ref="images" transform="translate(-50, -70)" clip-path="url(#clip-frame)">
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4724.jpg`" transform="matrix(0.8657,0,0,0.865686,362.9585,544.03)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4565.jpg`" transform="matrix(0.7787,0,0,0.778671,1003.8381,360.2884)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4623.jpg`" transform="matrix(0.4897,0,0,0.489651,1304.7295,376.1212)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4726.jpg`" transform="matrix(0.5987,0,0,0.598666,104.3116,103.0345)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4566.jpg`" transform="matrix(0.9326,0,0,0.932564,853.6786,402.1088)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4625.jpg`" transform="matrix(0.4396,0,0,0.439558,269.4867,601.1549)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4598.jpg`" transform="matrix(0.7989,0,0,0.798857,1436.9278,121.6152)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4679.jpg`" transform="matrix(0.4676,0,0,0.467585,748.3964,248.1779)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4604.jpg`" transform="matrix(0.9026,0,0,0.902649,126.3201,629.0749)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4774.jpg`" transform="matrix(0.8304,0,0,0.830359,1185.7226,624.1822)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4613.jpg`" transform="matrix(0.6829,0,0,0.682937,836.0344,673.0072)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4731.jpg`" transform="matrix(0.833,0,0,0.832991,483.5023,193.867)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4568.jpg`" transform="matrix(0.6168,0,0,0.616817,1360.9261,650.526)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4680.jpg`" transform="matrix(0.8843,0,0,0.884296,163.5943,251.0425)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4772.jpg`" transform="matrix(0.4475,0,0,0.447538,329.0298,271.424)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4573.jpg`" transform="matrix(0.6183,0,0,0.618285,334.0695,582.1666)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4717.jpg`" transform="matrix(0.8207,0,0,0.8207,498.3576,341.4483)" style="transform-origin: 0px 0px; opacity: 1;"/>
        <image :x="0" :y="0" :xlink:href="`${publicPath}scenes/present-day/IMG_4606.jpg`" transform="matrix(0.615,0,0,0.615031,382.0989,277.7252)" style="transform-origin: 0px 0px; opacity: 1;"/>
      </g>
    </svg>
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const tl = gsap.timeline({ paused: true })
    const images = Array.from(this.$refs["images"].querySelectorAll("image"))
    /* must set with gsap since tl is paused */
    gsap.set(images, {
      opacity: 0,
    })
    tl.to(images, { opacity: 1, stagger: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}
</style>
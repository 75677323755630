<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" fill="white" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>
      <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-roof/bg-color.jpg`" />
        </picture>
      </foreignObject>

      <g class="outline grass">
        <foreignObject ref="outline-1" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-roof/plant-1.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-2" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-roof/plant-2.png`" />
          </picture>
        </foreignObject>
      </g>
      
      <g class="outline man">
        <foreignObject ref="outline-1" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-roof/man-1.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-2" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-roof/man-2.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-3" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-roof/man-3.png`" />
          </picture>
        </foreignObject>
      </g>

      <foreignObject ref="clouds" x="0" y="0" :width="1920" :height="637">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-roof/clouds.png`" />
        </picture>
      </foreignObject>
    </svg>
    <SceneDialogue
      ref="dialogue"
      class="fancy-absolute"
      :width="0.275" :tx="0.975" :ty="0.6">
        Ray takes film footage of Max Littlesalt and George Jackson putting
        a roof on the darkroom. It is almost ready for him to work in!
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-18.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["clouds"], { opacity: 0, x: 10 })
    gsap.set(this.$refs["dialogue"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["clouds"], { duration: 1, opacity: 1, x: 0 }, "-=1")
    tl.to(this.$refs["dialogue"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
$anim-grass-length: 0.6s;

.outline.grass foreignObject {
  animation-name: grass; animation-iteration-count: infinite;
  animation-timing-function: linear; animation-duration: #{$anim-grass-length};
  &:nth-of-type(1) { animation-delay: 0s; }
  &:nth-of-type(2) { animation-delay: -#{$anim-grass-length * .5}; }
}

@keyframes grass {
  0% { opacity: 1; }
  50% { opacity: 0; }
}

$anim-man-length: 0.6s;

.outline.man foreignObject {
  animation-name: man; animation-iteration-count: infinite;
  animation-timing-function: steps(1,end); animation-duration: #{$anim-man-length};
  &:nth-of-type(1) { animation-delay: 0s; }
  &:nth-of-type(2) { animation-delay: -#{$anim-man-length * .34}; }
  &:nth-of-type(3) { animation-delay: -#{($anim-man-length * .66) }; }
}

@keyframes man {
  0% { opacity: 1; }
  33% { opacity: 0; }
  100% { opacity: 0; }
}
</style>
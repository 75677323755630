<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        
        <foreignObject
          :x="canvasWidth - 607 * 3 - 50"
          :y="canvasHeight - 420 * 3 + 175" :width="607 * 3" :height="420 * 3">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/injury/a.jpeg`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="narrative"
      bg="transparent"
			:width="0.725" :tx="0.1" :ty="0.1">
      <p>
        Back at Base Camp, Ray learns that a Navajo Expedition staff Member, Paul
        Goodman, was severely injured on the job. Ray and a group of Expedition
        members volunteer to work in Paul’s cornfield while he recovers.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
			:width="0.33" :tx="0.925" :ty="0.85">
			<em>
        “He’ll recover O.K. but it will take time. I’m leaving with a gang this morning
        to weed his cornfield for him. Spent two hours working in his field. Shot a
        movie sequence on it.” 
			</em>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-16.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}
</style>
<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        <g ref="main" :transform-origin="`${canvasWidth/2} ${0}`">
          <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
            <picture>
              <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-inside/BG.jpg`" />
            </picture>
          </foreignObject>

          <g>
            <g ref="photo1" class="photo">
              <image x="162" y="277" width="250" height="201" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-1.png`" />
              <image ref="photo1Detail" x="185" y="389" width="196" height="73" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-1-detail.png`" />
            </g>
            
            <g ref="photo2" class="photo">
              <image x="922" y="252" width="285" height="238" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-2.png`" />
              <image ref="photo2Detail" x="952" y="338" width="225" height="141" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-2-detail.png`" />
            </g>

            <g ref="photo3" class="photo">
              <image x="1638" y="179" width="274" height="226" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-3.png`" />
              <image ref="photo3Detail" x="1668" y="289" width="171" height="103" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-3-detail.png`" />
            </g>
          </g>

          <!-- <image ref="ppl" x="376" y="402" :width="1164" :height="678" :xlink:href="`${publicPath}scenes/darkroom-inside/ppl.png`" /> -->
          <image ref="walls" x="0" y="0" :transform-origin="`${canvasWidth/2} ${canvasHeight/2}`" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/darkroom-inside/walls.png`" />
        </g>
      </g>
    </svg>

    <SceneDialogue
      class="d1"
      :fontSize="30"
      ref="dialogue-1"
      bg="rgba(255,255,255,0.95)"
      :width="0.8" :tx="0.5" :ty="0.33">
      Press this button
      <span @touchstart="toggleAudio">
        <span class="audio-toggle" :class="{ active: audioActive }" @click="toggleAudio">
          <svg id="speaker" xmlns="http://www.w3.org/2000/svg" width="51.031" height="42.026" viewBox="0 0 51.031 42.026">
            <path :fill="audioActive ? '#e38900' : 'black'" d="M45.178,22.159a1.472,1.472,0,0,0-1.591.18L28.968,34.014H17.5a1.5,1.5,0,0,0-1.5,1.5V50.521a1.5,1.5,0,0,0,1.5,1.5H28.968L43.587,63.7a1.526,1.526,0,0,0,.931.33,1.785,1.785,0,0,0,.66-.15,1.5,1.5,0,0,0,.841-1.351V23.509A1.5,1.5,0,0,0,45.178,22.159ZM43.016,59.4,30.439,49.35a1.527,1.527,0,0,0-.931-.329H19V37.015H29.508a1.534,1.534,0,0,0,.931-.33L43.016,26.631Z" transform="translate(-16 -22.001)" />
            <path :fill="audioActive ? '#e38900' : 'black'" d="M66.279,22.218A1.508,1.508,0,1,0,64.718,24.8C72.433,29.42,76,35.212,76,43.016s-3.572,13.6-11.287,18.217a1.53,1.53,0,0,0-.51,2.071,1.563,1.563,0,0,0,1.291.721,1.444,1.444,0,0,0,.78-.21c8.554-5.132,12.728-11.8,12.728-20.8S74.833,27.35,66.279,22.218Z" transform="translate(-27.976 -22)" />
            <path :fill="audioActive ? '#e38900' : 'black'" d="M64.96,55.99h.54a1.437,1.437,0,0,0,1.111-.51,12.329,12.329,0,0,0,0-16.987,1.5,1.5,0,0,0-1.651-.51,1.53,1.53,0,0,0-.961,1.5V54.49A1.529,1.529,0,0,0,64.96,55.99Z" transform="translate(-27.978 -25.97)" />
          </svg>
        </span>
      </span>
      to activate audio, then turn up your device volume to hear Ray Garner’s daughter, Gay Mackintosh Garner, read each page aloud as you move through the book.
    </SceneDialogue>
    <SceneDialogue
      class="d2"
      bg="transparent"
      ref="dialogue-2"
      :fontSize="30"
      :width="0.833" :tx="0.5" :ty="0.66">
      We want to extend a huge thank you to Gay for carefully recording this narrative
      and generously sharing her father’s materials with us. We greatly enjoyed meeting
      her in 2018 and getting to know her and her family’s history. This website would
      not exist without her support and kindness.
    </SceneDialogue>
    <!-- <SceneAudio ref="scene-audio" :src="`${publicPath}scenes/map/audio-charlie-navajo.mp3`" /> -->
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap, { Elastic } from "gsap"
import { mapState, mapActions } from "vuex"

export default {
  extends: Template,
  mounted() {
    const tl = gsap.timeline({ paused: true })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["main"], { opacity: 0 })
    // gsap.set(this.$refs["walls"], { opacity: 0, scale: 1.2 })
    // gsap.set(this.$refs["ppl"], { y: 680 })
    // gsap.set(this.$refs["photo1Detail"], { opacity: 0 })
    // gsap.set(this.$refs["photo2Detail"], { opacity: 0 })
    // gsap.set(this.$refs["photo3Detail"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    // tl.to(this.$refs["main"], { duration: 4, scale: 1 }, 0)
    tl.to(this.$refs["main"], { duration: 1, opacity: 1 }, 0)
    // tl.to(this.$refs["ppl"], { duration: 2, ease: Elastic.easeOut.config(0.2, 0.4), y: 0 }, 1)
    // tl.to(this.$refs["walls"], { duration: 1, opacity: 1, scale: 1 }, 2.5)
    // tl.to(this.$refs["photo1Detail"], { duration: 1, opacity: 1 }, 4)
    // tl.to(this.$refs["photo2Detail"], { duration: 1, opacity: 1 }, 5)
    // tl.to(this.$refs["photo3Detail"], { duration: 1, opacity: 1 }, 6)
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 }, "-=0.5")
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 }, "-=0.5")

    this.tl = tl
  },
  computed: {
    ...mapState(["audioActive"]),
  },
  methods: {
    enter() {
      this.tl.play()
    },
    ...mapActions(['setAudioActive']),
    toggleAudio(e) {
      this.setAudioActive(!this.audioActive)
    },
  }
}
</script>

<style scoped lang="scss">
.boop {
  position: fixed;
  top: 0; left: 0;
  width: 100px; height: 100px;
  background: indianred;
  // animation: photo-in 2s infinite;
}

.photo {
  // animation: photo-in 2s infinite;

  // &:nth-of-type(1) {
  //   transform-origin: 181px 286px;
  // }

  // &:nth-of-type(2) {
  //   transform-origin: 454px 237px;
  // }
  
  // &:nth-of-type(3) {
  //   transform-origin: 752px 185px;
  // }
}

.d1 {
  text-align: center;
  font-weight: bold;
  // line-height: 1.5em;
}

.d2 {
  color: white;
  text-align: center;
}

.audio-toggle {
  display: inline-flex;
  margin: 0em 0.25em;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  border: 0.05em solid black;
}

.audio-toggle.active {
  border: 0.05em solid #e38900;
}

.audio-toggle svg {
  display: block;
  width: 1em;
  height: 1em;
}

@keyframes photo-in {
  0% { transform: rotateX(0deg) }
  33% { transform: rotateX(30deg) }
  66% { transform: rotateX(-30deg) }
  100% { transform: rotateX(0deg) }
}
</style>
<template lang="html">
  <code>Loading...</code>
</template>

<script>
export default {
}
</script>

<style lang="scss">
</style>

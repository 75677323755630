<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" fill="white" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}bg-texture.jpg`" />
        </picture>
      </foreignObject>
      
      <foreignObject ref="camera" x="-450" y="160" :width="canvasWidth" :height="canvasHeight">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/movie-shots/camera.png`" />
        </picture>
      </foreignObject>
    </svg>
    <SceneDialogue
			class="d1 futura-passata"
			ref="dialogue-1"
			bg="transparent"
			:width="0.95" :tx="0.1" :ty="0.12">
        <p>
          Ray gets settled into camp life and begins to carry out his duties as the
          Expedition filmmaker. During the summer he will document the Expedition
          activities, such as camp life and doing scientific work. In the rugged conditions,
          this job is challenging...
        </p>
    </SceneDialogue>
    <SceneDialogue
      class="d2"
			ref="dialogue-2"
			:bg="'transparent'"
			:width="0.475" :tx="0.875" :ty="0.6">
			<h2 class="entry-title futura-passata">July 1, 1937</h2>
			<p><em>
        “Earlier in the afternoon I took some movie shots of the bunch packing up
        preparatory to leaving for the Tsegi. Then disaster struck; the movie camera
        would only run for 7 & 8 seconds and then would automatically stop. Luckily,
        it happened on the last few feet of reel 2, for some shots that length of time
        is sufficient. I tried to find out what was wrong with it – wound and ran it
        countless times – yet the automatic stop would click at the end of eight minutes.
        …worked on it about three hours and finally located the cause. Putting it back
        together again was a job in itself…” 
			</em></p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-3.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["camera"], { opacity: 0, y: 10 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["camera"], { duration: 1, opacity: 1, y: 0 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 }, "-=1")
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
  .d1 {
    p {
      line-height: 1.5em;
    }
  }
  .d2 {
    .entry-title {
      font-size: 2em;
    }
    p {
      line-height: 1.5em;
    }
  }
	.entry-title {
		margin-bottom: 0.5em;
	}
</style>
<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        
        <foreignObject
          :x="canvasWidth / 2 - (500 * 2.5 / 2) - 275"
          :y="canvasHeight / 2 - (365 * 2.5 / 2) + 120"
          :width="500 * 2.5" :height="365 * 2.5">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/july-4/main.jpg`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 narrative white"
      bg="transparent"
			:width="0.8" :tx="0.1" :ty="0.1">
      <p>
        Ray and Martin Gambee stop at Ojleto Trading Post to camp and attend a Fourth
        of July field day in a nearby canyon. Navajos and Paiutes gather from all over
        the area. Ray and Martin watch a chicken pull and many foot races while
        documenting the sights.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="d2 white"
      bg="transparent"
			:width="0.275" :tx="0.966" :ty="0.566">
			<h2 class="entry-title futura-passata">July 4, 1937:</h2>
			<em>
        “The crowd laughed and shouted and everyone had a good time – though I do
        believe they would have felt better if Gambee and myself hadn’t been snapping
        pictures right and left!” 
			</em>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-5.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}
</style>
<template>
  <div ref="slide" class="slide">
    <div className="overlay"></div>
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" fill="rgba(30,30,30,1)" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        <foreignObject x="-225" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/camera-1/camera-1.jpg`" />
          </picture>
        </foreignObject>
        <foreignObject :x="canvasWidth-226" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/camera-1/camera-1.jpg`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    
    <SceneDialogue
      :width="0.2"
      :tx="0.05" :ty="0.18"
      :bg="`transparent`"
      :fontSize="25"
    >
      <span class="link futura-passata" @click="home">Home</span>
    </SceneDialogue>
    <SceneDialogue
      :width="0.2"
      :tx="0.05" :ty="0.28"
      :bg="`transparent`"
      :fontSize="25"
    >
      <span class="link futura-passata" @click="howTo">Instructions</span>
    </SceneDialogue>
    <SceneDialogue
      :width="0.2"
      :tx="0.05" :ty="0.38"
      :bg="`transparent`"
      :fontSize="25"
    >
      <span class="link futura-passata" @click="notes">Notes/Citations</span>
    </SceneDialogue>

    <SceneDialogue
      :width="0.08"
      :tx="0.995" :ty="0.1"
      :bg="`transparent`"
    >
      <div class="close-btn" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.31 132.31">
          <path class="bird" d="M92.38,29.12c-4.06-2.13-9,1.31-11.14,5.36S78.38,43.32,75.66,47c-4.79,6.5-14.14,7.6-20.33,12.78-4.06,3.4-6.49,8.32-9.79,12.46S37.25,80,32.05,79c6.66,2.9,12.39,7.86,19.36,9.94a26.6,26.6,0,0,0,23.15-4.26,28.56,28.56,0,0,0,11.1-20.89c0.4-6.52-1.39-13-1-19.54s3.83-13.73,10.22-15" stroke="rgba(30,30,30,1)" stroke-width="2" fill="rgba(30,30,30,1)"></path>
          <path class="bird" d="M50.06,111.43a26.86,26.86,0,0,1-21.3,11.26c3.3,3.64,7.24,7,12,8.27s10.4,0.22,13.42-3.66c4.38-5.6,1.7-13.79,3.13-20.76,0.34-1.65,1-3.39,2.5-4.2a8.21,8.21,0,0,0-4.58,2.06C53,106.33,51.73,109,50.06,111.43Z" stroke="rgba(30,30,30,1)" stroke-width="2" fill="rgba(30,30,30,1)"></path>
          <path class="bird" d="M34.48,44.14c-2.77-1.78-6.61-.58-9,1.69s-3.77,5.39-5.42,8.22A38.76,38.76,0,0,1,1.57,70a14,14,0,0,0,23.12-8.26c0.63-3.85-.35-7.88.69-11.64s5.73-7,8.74-4.51" stroke="rgba(30,30,30,1)" stroke-width="2" fill="rgba(30,30,30,1)"></path>
        </svg>
        <div class="label">CLOSE</div>
      </div>
    </SceneDialogue>

    <SceneDialogue
      :width="0.52"
      :tx="1" :ty="0.525"
      :bg="`transparent`"
    >
      <h1 class="futura-passata">Character Guide</h1>
      <ul class="char-list">
        <li><span class="name">Ray Garner</span> - Filmmaker on the 1937 Rainbow Bridge-Monument Valley Expedition (RBMVE) from Brooklyn, New York. His extensive journals provided the basis for this story.</li>
        <li><span class="name">Max Littlesalt</span> - Navajo RBMVE staff, packer, and interpreter from the Tsegi Canyon, Arizona area. </li>
        <li><span class="name">Martin Gambee</span> - Artist on the 1936, 1937, and 1938 RBMVE from Pawling, New York. </li>
        <li><span class="name">Jeremiah “Jerry” Hanson</span> - Head of mapping and survey crew on the 1937 RBMVE from Roseville, California. </li>
        <li><span class="name">Dwight Williamson</span> - Photographer on the 1937 RBMVE from Chicago, Illinois. He assisted with building the dark room in Marsh Pass, and also the mapping and survey crew.</li>
        <li><span class="name">John W. Bennett</span> - Archaeologist on the 1937 RBMVE, who went on to have a successful career as an anthropologist. He was born and raised in Wisconsin.</li>
        <li><span class="name">Ralph Beals</span> - Head of archaeology crew on the 1937 and 1938 RBMVE representing University of California, Los Angeles.  </li>
        <li><span class="name">Edward P. Beckwith</span> - A noted chemist from New York, New York that participated in the 1937 RBMVE as a photographer. </li>
        <li><span class="name">Harry and Leone “Mike” Goulding</span> - Owners of Goulding’s Trading Post and Lodge, which they established in the early 1920s.</li>
        <li><span class="name">“The-lefthanded-man’s-boy-with-moustache,-his son”</span> - A local Navajo boy hired as a guide to a natural bridge in the area near where his family was from. The area is described as being reached from the highway between monument valley and Kayenta, off a wagon road just south of Chaistla Butte. </li>
        <li><span class="name">Paul Goodman</span> - Navajo RBMVE staff.</li>
        <li><span class="name">Amy Andrews</span> - Pilot on the 1937 RBMVE from New York, New York. She was one of only two women participants in all of the field seasons.  </li>
        <li><span class="name">Charles Del Norte Winning</span> - Field Director for all years of the RBMVE from New York, New York.</li>
        <li><span class="name">George Jackson</span> - Archaeologist on the 1935 and 1937 RBMVE from Sandy Hook, Connecticut. </li>
      </ul>
    </SceneDialogue>
  </div>
</template>

<script>
import Template from "./Scenes/Template.vue"

export default {
  extends: Template,
  methods: {
    howTo() {
      this.$router.push("/how-to")
      this.close()
    },
    notes() {
      this.$router.push("/captions-1")
      this.close()
    },
    home() {
      this.$router.push("/")
      this.close()
    }
  },
  props: [
    "close"
  ]
}
</script>

<style lang="scss" scoped>
.slide {
  // width: 100vw;
  position: fixed;
  z-index: 2;
}

.canvas {
  z-index: 2;
}

.content {
  padding: 0;
}

.link {
  border: 0.04em solid black;
  padding: 0.3em 0.5em 0.2em;
  border-radius: 1em;
  background: white;
  color: black;
  cursor: pointer;
  &:hover {
    background: #E38900;
    color: white;
  }
}

.overlay {
  position: absolute;
  top: 0; left: 0;
  background: black;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.close-btn {
  cursor: pointer;
  margin: 0 auto;
  svg {
    width: 3.5em;
    transform: translateX(25%);
  }
  .label {
    text-align: center;
    margin-top: 0.5em;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: 0.1em;
  }
}

.char-list {
  height: 20em;
  overflow-y: scroll;
  padding-left: 1em;
  padding-right: 1em;

  .name {
    text-decoration: underline;
    font-weight: bold;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0.25);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
</style>
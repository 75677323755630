<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" fill="white" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <foreignObject ref="bg" x="0" y="0" :width="canvasWidth" :height="canvasHeight">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-complete/bg-color.jpg`" />
        </picture>
      </foreignObject>
      
      <g class="outline">
        <foreignObject ref="outline-1" x="0" y="28" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-complete/frame 1.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-2" x="0" y="28" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-complete/frame 2.png`" />
          </picture>
        </foreignObject>
        <foreignObject ref="outline-3" x="0" y="28" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-complete/frame 3.png`" />
          </picture>
        </foreignObject>
      </g>
      
      <image ref="sun" x="407" y="25" :width="216" :height="201" :xlink:href="`${publicPath}scenes/darkroom-complete/sun.png`" />
    </svg>
    <SceneDialogue
      ref="dialogue"
      class="d1 narrative text-right"
      bg="transparent"
      :width="0.66" :tx="0.95" :ty="0.2">
      <p>
        To this day, the darkroom still stands in Marsh Pass, AZ.
        Ray’s film footage and journals have been kept safely with family
        members and museum archives. Max Littlesalt’s descendents still live
        in the area today.
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-22.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["sun"], { opacity: 0, y: 10 })
    gsap.set(this.$refs["dialogue"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["sun"], { duration: 1, opacity: 1, y: 0 }, "-=1")
    tl.to(this.$refs["dialogue"].$el, { duration: 1, opacity: 1 }, 0.5)
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
$anim-length: 0.6s;

.outline foreignObject {
  animation-name: grass; animation-iteration-count: infinite;
  animation-timing-function: steps(0,end); animation-duration: #{$anim-length};
  &:nth-of-type(1) { animation-delay: 0s; }
  &:nth-of-type(2) { animation-delay: -#{$anim-length * .34}; }
  &:nth-of-type(3) { animation-delay: -#{($anim-length * .66) }; }
}

.d1 {
  p {
    font-size: 1.25em;
  }
}

@keyframes grass {
  0% { opacity: 1; }
  33% { opacity: 1; }
  34% { opacity: 0; }
  100% { opacity: 0; }
}
</style>
<template lang="html">
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" fill="white" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>
    </svg>

    <SceneDialogue
      :ref="`dialogue`"
      :width="1"
      :styles='{padding: "0"}'
      :tx="0" :ty="0.5"
      bg="transparent"
    >
      <div id="HowTo">
        <div class="tuts-cnr">
          <div class="tut mobile">
            <h1 class="heading futura-passata bold">Mobile</h1>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="697" height="374" viewBox="0 0 697 374">
              <rect id="MobileScreen" fill="white" x="82" y="30" width="480" height="315" />
              <defs>
                <clipPath id="MobileScreenClip">
                  <use id="MobileScreenClipper" xlink:href="#MobileScreen" />
                </clipPath>
              </defs>
              <image class="phone" width="697" height="374" :xlink:href="`${publicPath}scenes/how-to/mobile.png`" />
              <g clip-path="url(#MobileScreenClip)">
                <image class="thumb" x="87" y="35" width="470" height="309" :xlink:href="`${publicPath}scenes/how-to/preview.jpg`" />
              </g>
            </svg>
            <ul class="steps">
              <li><span @touchstart="openA2hsModal" @click="openA2hsModal" class="a2hs-cta">Add to Home Screen</span> for best experience!</li>
              <li v-for="(s,i) in mobileTut.steps" :key="i">{{s}}</li>
            </ul>
          </div>
          <div class="tut desktop">
            <h1 class="heading futura-passata bold">Desktop</h1>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="590" height="533" viewBox="0 0 590 533">
              <image width="590" height="533" :xlink:href="`${publicPath}scenes/how-to/desktop.png`" />
              <image x="44" y="43" width="499" height="310" :xlink:href="`${publicPath}scenes/how-to/preview.jpg`" />
            </svg>
            <ul class="steps">
              <li v-for="(s,i) in desktopTut.steps" :key="i">{{s}}</li>
            </ul>
          </div>
        </div>
      </div>
    </SceneDialogue>

    <SceneDialogue
      class="a2hs-modal"
      v-bind:class="{none: !a2hsGuide}"
      :tx=".5" :ty=".5"
      :width=".66"
      bg="rgba(255,255,255,1)"
    >
      <svg @touchstart="closeA2hsModal" @click="closeA2hsModal" class="close" viewBox="0 0 100 100">
        <line x1="4" x2="96" y1="4" y2="96" stroke="black" stroke-width="8" />
        <line x1="4" x2="96" y1="96" y2="4" stroke="black" stroke-width="8" />
      </svg>
      <h2>iPhone / iPad</h2>
      <p>Tap the share button <img class="os-icon" :src="`${publicPath}scenes/how-to/ios-share.png`" /> and select "Add To Home Screen"</p>
      <br>
      <h2>Android</h2>
      <p>Tap the menu button <img class="os-icon" :src="`${publicPath}scenes/how-to/android-menu.png`" /> and select "Add to homescreen"</p>
    </SceneDialogue>
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
    desktopTut,
    mobileTut,
    partners,
    a2hsGuide: false,
  }),
  extends: Template,
  computed: {
    current() {
      return this.$store.state.current || this.$store.getters.slide(0).slug
    },
  },
  mounted() {
    const tl = gsap.timeline({ paused: true })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 }, 0)
    tl.to(this.$refs["dialogue"].$el, { duration: 1, opacity: 1 }, 0)
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
    openA2hsModal(e) {
      e.stopPropagation()
      e.preventDefault()
      this.a2hsGuide = true
    },
    closeA2hsModal(e) {
      e.stopPropagation()
      e.preventDefault()
      this.a2hsGuide = false
    },
  }
}

const desktopTut = {
  steps: [
    "Use arrow keys to go forward and back",
    "Move cursor to reveal / hide timeline",
    "Click on timeline to skip ahead",
  ]
}

const mobileTut = {
  steps: [
    // "Add to Home Screen for best experience!",
    "Tap screen to reveal / hide navigation",
    "Swipe screen left or right to turn the page",
    // "Slide along timeline to skip around",
    "Use two fingers to zoom in & out",
  ]
}

const partners = [
  { name: "The Onward Project", file: "OnwardSlug.png" },
  { name: "Museum of Northern Arizona", file: "mnalogo-transparent.png" },
  { name: "EC Compass", file: "EC_Compass_TM.jpg" },
  { name: "Center of Southwest Studies", file: "CSWS_2color_copy.jpg" },
  { name: "Fowler Museum at UCLA", file: "FM@UCLA-Logo-Blue-Gray-Transparent.png" },
]
</script>

<style lang="scss">
#HowTo {
  background: white; min-height: 100%; text-align: center;
  display: flex; flex-flow: column; justify-content: center;
  -webkit-overflow-scrolling: touch;
  .tuts-cnr {
    display: grid; grid-template-columns: 1fr 1fr; margin-bottom: 2em;
    svg { width: 15em; height: auto; }
    .mobile {
      svg { overflow: visible; }
      #MobileScreenClipper { transform-origin: 348.5px 187px; animation: landscape-phone 2s ease both 2s; }
      .phone { transform-origin: 348.5px 187px; animation: landscape-phone 2s ease both 2s; }
      .thumb { transform-origin: 348.5px 187px; animation: landscape-thumb 2s ease both 2s; }
    }
    .tut {
      display: grid; grid-template-columns: 1fr; grid-template-rows: min-content 1fr min-content;
      justify-items: center; align-items: center; text-align: center;
      &.desktop { order: 1 }
      &.mobile { order: 2 }
      .heading { margin: 0.5em auto; text-transform: uppercase; font-size: 2em; } }
    ul.steps {
      list-style-type: none; padding: 2em 0 0; margin: 0 auto;
      li { margin-bottom: 0.5em; font-size: 1.5em; font-style: italic; } }
  }
  .rad-button { font-size: 1.5em; line-height: 0.8em; }
  footer { margin-top: 4em; }
  .logos-cnr {
    display: block; justify-content: space-around; align-items: center;
    max-width: 800px; margin: 2em auto 2em;
    > * { max-height: 80px; max-width: 300px; vertical-align: middle; margin: 1em; }
  }
  .a2hs-cta {
    color: blue;
    // color: #E38900;
    text-decoration: underline;
    cursor: pointer;
  }
}

.a2hs-modal {
  box-shadow: 0em 0em 0.5em 0em rgba(0,0,0,0.5);

  .close {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
  }

  h2 {
    font-weight: bold;
  }

  p {
    font-size: 1.25em;
    margin: 0;
  }

  .os-icon {
    max-width: 1.25em;
    max-height: 1.25em;
    width: auto;
    height: auto;
    vertical-align: middle;
  }
}

@keyframes landscape-phone {
  0% { transform: rotate(90deg) scale(0.8); }
  50% { transform: rotate(0deg) scale(1); }
  100% { transform: rotate(0deg) scale(1); }
}
@keyframes landscape-thumb {
  0% { transform: scale(0.72) translate(26px, -25px); }
  50% { transform: scale(0.72) translate(26px, -25px) rotate(-90deg); }
  100% { transform: scale(1) translate(0px, 0px) rotate(0deg); }
}

@media screen and (max-width: 480px) {
  #HowTo {
    .tuts-cnr {
      .tut { .heading { font-size: 2.5em; } }
      ul.steps {
        li { font-size: 1.4em; }
      }
    }
  }
}
</style>

<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        <image ref="pa" x="100" y="220" :width="595*1.15" :height="392*1.15" :xlink:href="`${publicPath}scenes/july-9/a.jpg`" />
        <image ref="pb" x="966" y="30" :width="1600 * .5" :height="1416 * .5" :xlink:href="`${publicPath}scenes/july-9/b.jpg`" />
      </g>
    </svg>
    <SceneDialogue
      class="d1 narrative white"
      bg="transparent"
			ref="dialogue-1"
			:width="0.45" :tx="0.033" :ty="0.1">
      <p>
        They spend several days with the biologists. Camping out in the open has its
        challenges when the weather gets bad... 
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="white"
			:width=".45" :tx="0.1" :ty="0.825"
      bg="transparent"
      className="entry">
			<h2 class="entry-title futura-passata">July 9, 1937</h2>
      <em>
        “A violent wind and rain storm caught me a way out on the desert. Ran back,
        crouching over the cameras to keep them dry, to the shelter of a large rock
        beneath the Totem Pole.  ...Erosion goes on at a tremendous rate every time it
        rains out here – the many small streams I had to cross were as thick as hot
        chocolate with sediment.
      </em>
    </SceneDialogue>
    <SceneDialogue
      ref="dialogue-3"
      class="white"
      :width=".45" :tx="0.925" :ty="0.84"
      bg="transparent">
      <em>
        My sleeping bag was soaked. ...The air mattress was soaked too, and I
        didn’t dare inflate it. The ground was cold and hard. Spent a very uncomfortable
        night. Also froze to death. Yes, on the Arizona desert in July!!! But the
        sparkling stars were beautiful.” 
      </em>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-7.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-3"].$el, { opacity: 0 })
    gsap.set(this.$refs["pa"], { opacity: 0 })
    gsap.set(this.$refs["pb"], { opacity: 0 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["pa"], { duration: 1, opacity: 1 }, "+=0.5")
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["pb"], { duration: 1, opacity: 1 }, "+=0.5")
    tl.to(this.$refs["dialogue-3"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.entry-title {
		margin-bottom: 0.5em;
	}
  .entry {
    color: white;
  }
  .d1 p {
    font-size: 1.1em;
  }
</style>
import { createWebHistory, createRouter } from "vue-router"
import Cover      from "../components/Cover.vue"
import Stage    from "../components/Stage"
import baseUrl   from "../constants/baseUrl"

const routes = [
  { path: "/", component: Cover },
  { path: "/:slug", component: Stage, name: "stage" },
]

export default createRouter({
  routes,
  history: createWebHistory(),
  base: baseUrl,
})

<template></template>

<script>
import { mapActions } from "vuex"

export default {
  computed: {
    active() {
      return this.$store.state.audio
    }
  },
  mounted() {
    this.setAudioSrc(this.src) // Set src which is played by Audio.vue component
  },
  methods: {
    ...mapActions(["setAudioSrc"])
  },
  props: [ 'src' ]
}
</script>
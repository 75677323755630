<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        <g ref="main" :transform-origin="`${canvasWidth/2} ${0}`">
          <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
            <picture>
              <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-inside/BG.jpg`" />
            </picture>
          </foreignObject>

          <g>
            <g ref="photo1" class="photo">
              <image x="162" y="277" width="250" height="201" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-1.png`" />
              <image ref="photo1Detail" x="185" y="389" width="196" height="73" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-1-detail.png`" />
            </g>
            
            <g ref="photo2" class="photo">
              <image x="922" y="252" width="285" height="238" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-2.png`" />
              <image ref="photo2Detail" x="952" y="338" width="225" height="141" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-2-detail.png`" />
            </g>

            <g ref="photo3" class="photo">
              <image x="1638" y="179" width="274" height="226" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-3.png`" />
              <image ref="photo3Detail" x="1668" y="289" width="171" height="103" :xlink:href="`${publicPath}scenes/darkroom-inside/photo-3-detail.png`" />
            </g>
          </g>

          <image ref="ppl" x="376" y="402" :width="1164" :height="678" :xlink:href="`${publicPath}scenes/darkroom-inside/ppl.png`" />
          <image ref="walls" x="0" y="0" :transform-origin="`${canvasWidth/2} ${canvasHeight/2}`" :width="canvasWidth" :height="canvasHeight" :xlink:href="`${publicPath}scenes/darkroom-inside/walls.png`" />
        </g>
      </g>
    </svg>

    <SceneDialogue
      class="fancy-absolute"
      ref="dialogue"
      :width="0.25" :tx="0.95" :ty="0.8">
      Max finishes the darkroom so Ray can wrap up the summer's work. He has to develop
      all the film footage he has taken.
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-21.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap, { Elastic } from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["main"], { opacity: 0, scale: 1.5 })
    gsap.set(this.$refs["walls"], { opacity: 0, scale: 1.2 })
    gsap.set(this.$refs["ppl"], { y: 680 })
    gsap.set(this.$refs["photo1Detail"], { opacity: 0 })
    gsap.set(this.$refs["photo2Detail"], { opacity: 0 })
    gsap.set(this.$refs["photo3Detail"], { opacity: 0 })
    gsap.set(this.$refs["dialogue"].$el, { opacity: 0 })
    tl.to(this.$refs["main"], { duration: 4, scale: 1 }, 0)
    tl.to(this.$refs["main"], { duration: 1, opacity: 1 }, 0)
    tl.to(this.$refs["ppl"], { duration: 2, ease: Elastic.easeOut.config(0.2, 0.4), y: 0 }, 1)
    tl.to(this.$refs["walls"], { duration: 1, opacity: 1, scale: 1 }, 2.5)
    tl.to(this.$refs["photo1Detail"], { duration: 1, opacity: 1 }, 4)
    tl.to(this.$refs["photo2Detail"], { duration: 1, opacity: 1 }, 5)
    tl.to(this.$refs["photo3Detail"], { duration: 1, opacity: 1 }, 6)
    tl.to(this.$refs["dialogue"].$el, { duration: 1, opacity: 1 })


    // gsap.set(this.$refs["photo2"], { transformOrigin: "454px 237px" })
    // gsap.fromTo(this.$refs["photo2"], { css: { transform: `rotateX(30deg)` } }, { css: { transform: `rotateX(-30deg)` }, repeat: -1 })
    // gsap.fromTo(this.$refs["photo2"], { opacity: 0 }, { opacity: 1, duration: 2, repeat: -1 })

    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
.boop {
  position: fixed;
  top: 0; left: 0;
  width: 100px; height: 100px;
  background: indianred;
  // animation: photo-in 2s infinite;
}

.photo {
  // animation: photo-in 2s infinite;

  // &:nth-of-type(1) {
  //   transform-origin: 181px 286px;
  // }

  // &:nth-of-type(2) {
  //   transform-origin: 454px 237px;
  // }
  
  // &:nth-of-type(3) {
  //   transform-origin: 752px 185px;
  // }
}

@keyframes photo-in {
  0% { transform: rotateX(0deg) }
  33% { transform: rotateX(30deg) }
  66% { transform: rotateX(-30deg) }
  100% { transform: rotateX(0deg) }
}
</style>
<template lang="html">
  <div ref="slide" class="slide">
    <svg class="canvas" :viewBox="`0 0 ${coverWidth} ${coverHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="coverWidth" :height="coverHeight" />
      <image
        ref="image"
        :transform-origin="`${coverWidth/2} ${coverHeight/2}`"
        :width="coverWidth"
        :height="coverHeight"
        :href="`${publicPath}scenes/${slide.scene}`"
        :xlink:href="`${publicPath}scenes/${slide.scene}`" />
        <g class="content" v-bind:class="{hide: !showContent}"
           :transform="`translate(${coverWidth - contentWidth} ${coverHeight/2 - (dy/2)})`">
          <rect
            ref="contentBg" x="0" y="0"
            :width="contentWidth"
            fill="rgba(255,255,255,0.9)"
            stroke="black" stroke-width="2" />
          <g :transform="`translate(${contentPadding} ${contentPadding*1.5})`">
            <text font-size="15" ref="contentText"></text>
          </g>
        </g>
    </svg>
  </div>
</template>

<script>
import textFlow from "../helpers/textFlow.js"

export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
    coverWidth: 1000,
    coverHeight: 720,
    contentWidth: 330,
    contentPadding: 20,
    dy: 0,
    showContent: false,
  }),
  mounted() {
    setTimeout(() => { // getting inconsistent results from textFlow without this...
      const textNode = this.$refs["contentText"]
      const text = this.$props.slide.content
      const maxWidth = this.contentWidth - this.contentPadding * 2
      const dy = textFlow(text, textNode, maxWidth, 0, 20, false)
      this.$refs["contentBg"].setAttribute("height", dy + this.contentPadding*1.5)
      this.dy = dy
      this.showContent = true
    }, 100)
  },
  props: ["slide"]
}
</script>

<style lang="scss">
.slide {
  height: 100%; width: 100%; background-size:contain; background-repeat: no-repeat; background-position: center;
  .canvas { width: 100%; height: 100%; }
  // .content {
  //   opacity: 1; transition: opacity 0.5s;
  //   &.hide { opacity: 0; }
  // }
}
</style>

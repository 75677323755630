<template lang="html">
  <div id="Stage"
       @mousemove="handleMouse"
       @touchstart="swipeStart"
       @touchmove="swipeMove"
       @touchend="swipeEnd">
    <transition mode="out-in" name="scene-transition" :duration="500">
      <component v-bind:is="current"></component>
    </transition>
    <Loader v-if="loader" />
    <Nav :show="displayUI" :setShow="setDisplayUI" :uiOverride="setDisplayUIOverride" />
    <NavMobile :show="displayUI" :setShow="setDisplayUI" :uiOverride="setDisplayUIOverride" />
    <Controls :show="displayUI" :uiOverride="setDisplayUIOverride" />
    <Escape :show="displayUI" :active="escapeActive" :set="setEscape" :uiOverride="setDisplayUIOverride" />
    <Audio />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Slide                    from "./Slide.vue"
import Nav                      from "./Nav.vue"
import NavMobile                from "./NavMobile.vue"
import Escape                   from "./Escape.vue"
import Loader                   from "./Loader.vue"
import Audio                      from "./Audio.vue"
import FullscreenToggler        from "./FullscreenToggler.vue"
import Controls                 from "./Controls.vue"

import HowTo          from "./Scenes/HowTo.vue"
import Introduction          from "./Scenes/Introduction.vue"
import Narration          from "./Scenes/Narration.vue"
import DarkroomStart      from "./Scenes/DarkroomStart.vue"
import MapMarshPass      from "./Scenes/MapMarshPass.vue"
import MovieShots      from "./Scenes/MovieShots.vue"
import FirstExpedition      from "./Scenes/FirstExpedition.vue"
import July4th      from "./Scenes/July4th.vue"
import TotemPole      from "./Scenes/TotemPole.vue"
import July9 from "./Scenes/July9.vue"
import August8_9 from "./Scenes/August8_9.vue"
import CobraHead from "./Scenes/CobraHead.vue"
import BennettPainting from "./Scenes/BennettPainting.vue"
import Hotevilla from "./Scenes/Hotevilla.vue"
import DarkroomNight      from "./Scenes/DarkroomNight.vue"
import Totem2 from "./Scenes/Totem2.vue"
import Gouldings from "./Scenes/Gouldings.vue"
import DoubleRainbow from "./Scenes/DoubleRainbow.vue"
import Injury from "./Scenes/Injury.vue"
import Plane from "./Scenes/Plane.vue"
import DarkroomRoof      from "./Scenes/DarkroomRoof.vue"
import HopiSnakeDance from "./Scenes/HopiSnakeDance.vue"
import Postcard from "./Scenes/Postcard.vue"
import DarkroomInside      from "./Scenes/DarkroomInside.vue"
import DarkroomComplete      from "./Scenes/DarkroomComplete.vue"
import PresentDay1 from "./Scenes/PresentDay1.vue"
import PresentDay2 from "./Scenes/PresentDay2.vue"
import TheEnd from "./Scenes/TheEnd.vue"
import ThankYou from "./Scenes/ThankYou.vue"
import Captions1 from "./Scenes/Captions1.vue"
import Captions2 from "./Scenes/Captions2.vue"

const sceneMap = {
  "how-to": HowTo,
  "narration": Narration,
  "introduction": Introduction,
  "page-1": DarkroomStart,
  "page-2": MapMarshPass,
  "page-3": MovieShots,
  "page-4": FirstExpedition,
  "page-5": July4th,
  "page-6": TotemPole,
  "page-7": July9,
  "page-8": August8_9,
  "page-9": CobraHead,
  "page-10": BennettPainting,
  "page-11": Hotevilla,
  "page-12": DarkroomNight,
  "page-13": Totem2,
  "page-14": Gouldings,
  "page-15": DoubleRainbow,
  "page-16": Injury,
  "page-17": Plane,
  "page-18": DarkroomRoof,
  "page-19": HopiSnakeDance,
  "page-20": Postcard,
  "page-21": DarkroomInside,
  "page-22": DarkroomComplete,
  "page-23": PresentDay1,
  "page-24": PresentDay2,
  "page-25": TheEnd,
  "thank-you": ThankYou,
  "captions-1": Captions1,
  "captions-2": Captions2,
}

export default {
  data: function() { return {
    escapeActive: false,
    navActive: false,
    uiTmt: null,
    touchTmt: null,
    touchHot: false,
    displayUI: false,
    displayUIOverride: false,
    swipeBegin: 0,
    swipeHot: false,
  } },
  computed: {
    ...mapState([
      "slides",
      "sequence",
      "loader",
      "current",
    ]),
  },
  watch: {
    $route: function(to, from) {
      this.setLoader(true)
      this.setCurrent(to.params.slug)
    },
  },
  created() {
    this.setLoader(true)
    this.setCurrent(this.$route.params.slug)
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    ...mapActions(['setLoader', 'setCurrent']),
    setDisplayUI(to) { this.displayUI = to },
    setDisplayUIOverride(to) { this.displayUIOverride = to },
    setEscape(to) { this.escapeActive = to },
    handleScroll() {
      // if scrolling window then we must be zoomed in
      this.swipeHot = false
      this.swiping = false
      window.clearTimeout(this.swipeTimeout)
    },
    handleMouse() {
      window.clearTimeout(this.uiTmt)
      if (!this.displayUI) { this.setDisplayUI(true) }
      this.uiTmt = window.setTimeout(() => {
        if (this.displayUIOverride) { return }
        this.setDisplayUI(false)
      }, 1000)
    },
    swipeStart(e) {
      // console.log(e.targetTouches)
      if (e.targetTouches.length > 1) {
        this.swipeHot = false
        this.swiping = false
        // window.clearTimeout(this.swipeTimeout)
        return
      }
      this.swipeHot = true
      window.clearTimeout(this.swipeTimeout)
      this.swipeTimeout = window.setTimeout(() => {
        this.swipeHot = false
        this.swiping = false
      }, 500)
      this.swipeBeginX = e.targetTouches[0].clientX
    },
    swipeMove(e) {
      if (e.targetTouches.length > 1) { return }
      if (this.swipeHot) { this.swiping = true }
    },
    swipeEnd(e) {
      e.preventDefault()
      if (e.targetTouches.length > 1) {
        this.swipeHot = false
        this.swiping = false
        // window.clearTimeout(this.swipeTimeout)
        return
      }
      if (!this.swiping) {
        this.setDisplayUI(!this.displayUI)
        return
      }
      window.clearTimeout(this.swipeTimeout)
      this.swiping = false

      const x = e.changedTouches[0].clientX
      if (x < this.swipeBeginX - 100) {
        const curr = this.sequence.findIndex(s => this.current === s)
        const next = this.sequence[curr+1]
        if (next) { this.$router.push(`/${next}`) }
      }
      if (x > this.swipeBeginX + 100) {
        const curr = this.sequence.findIndex(s => this.current === s)
        const prev = this.sequence[curr-1]
        if (prev) { this.$router.push(`/${prev}`) }
      }
    },
  },
  components: {
    Slide,
    Nav,
    NavMobile,
    Escape,
    Loader,
    FullscreenToggler,
    Controls,
    Audio,
    ...sceneMap,
  }
}
</script>

<style lang="scss">
#Stage {
  width: 100%; height: 100%; overflow: hidden; -webkit-overflow-scrolling: auto;
}
.slide {
  height: 100%; width: 100%; background-size:contain;
  background-repeat: no-repeat; background-position: center;
  transition: opacity 0.5s ease;
  &.scene-transition-leave-active { opacity: 0 }
  .canvas { width: 100%; height: 100%; }
}
</style>

import { createStore, createLogger } from "vuex"
import baseUrl from "../constants/baseUrl"
import fetch from "unfetch"

export default createStore({
  plugins: [createLogger({})],
  state: {
    slides: null,
    current: null,
    sequence: [],
    loader: false,
    audioActive: false,
    audioSrc: null,
    showAudioControls: false,
    audioPulse: false,
    audioPlay: false,
  },
  mutations: {
    slides (state, slides) { state.slides = slides },
    current (state, current) { state.current = current },
    sequence (state, sequence) { state.sequence = sequence },
    loader (state, boo) { state.loader = boo },
    audioActive (state, boo) { state.audioActive = boo },
    audioSrc (state, src) { state.audioSrc = src },
    audioPulse (state, boo) { state.audioPulse = boo },
    audioPlay (state, boo) { state.audioPlay = boo },
    showAudioControls (state, boo) { state.showAudioControls = boo },
  },
  getters: {
    slide: (state) => (key) => {
      switch (typeof key) {
        case "number":
          return state.slides.get(state.sequence[key])
        case "string":
          return state.slides.get(key)
        default:
          console.error("Attempted to fetch slide without valid key")
      }
    }
  },
  actions: {
    fetchSlides ({commit}) {
      return fetch(`${baseUrl}slides.json`)
        .then(r => {
          return r.json()
        })
        .then(d => {
          const m = new Map()
          d.forEach(s => { m.set(s.slug, s) })
          commit("slides", m)
          commit("sequence", d.map(s => s.slug))
        })
    },
    setCurrent ({commit}, key) { commit("current", key) },
    setLoader ({commit}, to) { commit("loader", to) },
    setAudioActive ({commit}, to) { commit("audioActive", to) },
    setAudioSrc ({commit}, to) { commit("audioSrc", to) },
    setAudioPulse ({commit}, to) { commit("audioPulse", to) },
    setShowAudioControls ({commit}, to) { commit("showAudioControls", to) },
    audioPulse({commit}) {
      commit("audioPulse", true)
      window.setTimeout(() => commit("audioPulse", false), 0)
    },
    audioPlay({commit}) { commit("audioPlay", true) },
    audioPause({commit}) { commit("audioPause", false) },
    audioReset({commit}) {
      commit("audioSrc", null)
      commit("audioPlay", false)
    }
  }
})
<template>
  <router-view v-if="slides"></router-view>
  <Loading v-else />
</template>

<script>
import { mapState, mapActions } from "vuex"
import Loading from "./components/Loading.vue"

export default {
  name: "app",
  computed: mapState([
    "slides"
  ]),
  mounted() {
    this.fetchSlides()
  },
  methods: {
    ...mapActions([
      "fetchSlides",
    ])
  },
  components: {
    Loading,
  }
}
</script>

<style lang="scss">
@import "assets/fonts/Rockwell/style.css";
@import "assets/fonts/SanFrancisco/style.css";
@import "assets/fonts/Minion-Pro/stylesheet.css";
@import "assets/fonts/FuturaPassata/stylesheet.css";
@import "assets/fonts/OPTIMorgan-Two/stylesheet.css";

html, body, #app { height: 100%; width: 100%; overflow: hidden; -webkit-overflow-scrolling: auto; }
body { margin: 0; padding: 0; background: #0A0A0A; overflow: hidden; font-family: "Minion Pro"; }
h1, h2, h3, h4, h5 { margin: 0; font-weight: normal; }
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}
/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}
/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

* { box-sizing: border-box; }

.rockwell { font-family: "Rockwell";
  &.bold {
    line-height: 0.3em;
    &:before { content: ""; display: block; height: 0.4em; } } } // TODO: New font file
.san-francisco { font-family: "San Francisco Text" }
.minion { font-family: "Minion Pro" }
.futura-ag { font-family: "FuturaAgMiteOpti" }
.futura-passata { font-family: "Futura Passata DISPLAY" }
.opti-morgan { font-family: "OPTIMorgan" }
.agrandir-narrow { font-family: "Agrandir Narrow" }
.bold { font-weight: bold; }
.italic { font-style: italic; }
.medium { font-weight: 500; }
.gold { color: #E38900; }
.teal { color: #69AB9E; }
.white { color: white; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.none { display: none; }
#app { overflow: auto; }

.narrative {
  font-family: "Futura Passata DISPLAY";
  p {
    line-height: 1.25em;
  }
}

.fancy-absolute {
  position: absolute;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 0.5em);
    height: calc(100% - 0.5em);
    transform: translate(-50%, -50%);
    border: 1px solid currentColor;
    opacity: 0.5;
  }
}
</style>

<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect
        id="PlaneFrame"
        :width="1522 * 0.9"
        :height="1137 * 0.9"
        :x="canvasWidth / 2 - 1522 * 0.9 / 2"
      />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>
      <clipPath id="plane-frame">
        <use xlink:href="#PlaneFrame"/>
      </clipPath>

      <g clip-path="url(#plane-frame)">
        <image
          ref="photo"
          :width="1522 * 0.9"
          :height="1137 * 0.9"
          :x="canvasWidth / 2 - 1522 * 0.9 / 2"
          :xlink:href="`${publicPath}scenes/plane/a.jpeg`"
        />

        <image ref="plane" :width="402" :height="435" :xlink:href="`${publicPath}scenes/plane/Plane.png`" />
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 narrative fancy-absolute"
			:width="0.5" :tx="0.033" :ty="0.1">
      <p>
        Every once in a while there is an exciting day at camp.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="d2"
			:width="0.2825" :tx="0.95" :ty="0.775">
      <p>
        <em>
          “...The expedition plane just passed over Camp on first flight. Miss Andrews (pilot), Beckwith and Winning aboard. Quite a thrill.”
        </em>
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-17.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    
    const planeTl = gsap.timeline({ paused: true, repeat: -1, repeatDelay: 0.5 })

    gsap.set(this.$refs["plane"], { x: 1600, y: 1000 })
    planeTl.to(this.$refs["plane"], { duration: 5, x: -800, y: -1200 })
    planeTl.set(this.$refs["plane"], { transformOrigin: "center", rotate: 100, x: -200, y: 1000 })
    planeTl.to(this.$refs["plane"], { duration: 3, x: 800, y: 300, ease: "power2.out" })
    planeTl.to(this.$refs["plane"], { duration: 2, x: 1600, y: -600, ease: "power2.in" }, "+=0.5")
    planeTl.set(this.$refs["plane"], { rotate: -40, x: 1600, y: 225 })
    planeTl.to(this.$refs["plane"], { duration: 5, x: -1000, y: 225 }, "+=0.5")

    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["photo"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["photo"], { duration: 1, opacity: 1, onComplete() { planeTl.play() } })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 }, "+=2")
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}

  .d1 p {
    margin: 0.25em auto 0.2em;
    text-align: center;
  }

  .d2 p {
    line-height: 1.5em;
    margin: auto;
    /* font-weight:bold; */
  }
</style>
<template lang="html">
  <div id="Home">
    <svg id="Cover" :viewBox="`0 0 ${coverWidth} ${coverHeight}`">
    <defs>
      <filter id="shadow">
        <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2"/>
      </filter>
      <filter id="shadow2">
        <feDropShadow dx="0" dy="0" stdDeviation="0.5"
            flood-color="cyan"/>
      </filter>
      <filter id="shadow3">
        <feDropShadow dx="-0.8" dy="-0.8" stdDeviation="0"
            flood-color="pink" flood-opacity="0.5"/>
      </filter>
    </defs>

      <g
        ref="image"
        :transform-origin="`${coverWidth/2} ${coverHeight/2}`"
        :style="{transformOrigin: `${coverWidth/2} ${coverHeight/2}`}">
        <foreignObject x="0" y="0" :width="coverWidth" :height="coverHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/cover/main.jpg`" />
          </picture>
        </foreignObject>
      </g>

      <g class="title"
        :transform="`translate(${coverWidth/2} ${coverHeight/2 - 120})`"
      >
        <text
          ref="titleMain"
          text-anchor="middle" fill="#ffc94c" font-size="120"
          dominant-baseline="middle" letter-spacing="8" class="opti-morgan main-title">
          <tspan x="5" text-anchor="middle">WEST OF MY</tspan>  
          <tspan x="5" text-anchor="middle" dy="1.1em">DREAMS:</tspan>  
        </text>

        <text
          ref="titleSub"
          text-anchor="middle" fill="white" font-size="70" dy="260" font-weight="bold"
          dominant-baseline="middle" class="futura-passata sub-title">
          A STORY OF RAY GARNER
        </text>
      </g>

      <g class="cta" ref="cta" @click="begin"
        :transform="`translate(${coverWidth/2 - 360/2} ${coverHeight/2 + 235})`"
      >
        <clipPath id="cta-clip">
          <rect :x="52.5" width="250" height="75" fill="black"></rect>
        </clipPath>
        
        <rect fill="#ffe972" :x="52.5" :width="250" :height="75" :rx="75/2" />
        <g class="hover-bg" clip-path="url(#cta-clip)">
          <rect :x="52.5" :rx="75/2" fill="#E38900" width="250" height="75" />
        </g>
        <text
          text-anchor="middle" fill="black" font-size="40" font-weight="bold"
          dominant-baseline="middle" alignment-baseline="middle" dx="180"
          dy="39" class="futura-passata">BEGIN</text>
      </g>
    </svg>
    <div id="HomeMask" ref="mask" :style="{display: mask ? 'block' : 'none'}"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import gsap from "gsap"

function prepText(...texts) {
  const svgns = "http://www.w3.org/2000/svg"
  texts.forEach(t => {
    const tspans = Array.from(t.children)
    tspans.forEach(ts => {
      let prevDims = null
      const text = ts.textContent
      const fontSize = ts.getAttribute("font-size")
      const className = ts.className.baseVal
      const fill = ts.getAttribute("fill")
      const yBase = ts.getAttribute("y")
      const xBase = ts.getAttribute("x")
      ts.textContent = ""
      for (let l of text) {
        let space = l.charCodeAt(0) === 32
        let n = document.createElementNS(svgns, "text")
        n.setAttribute("font-size", fontSize)
        n.setAttribute("fill", fill)
        if (space) { n.setAttribute("opacity", 0) }
        if (yBase) { n.setAttribute("y", yBase) }
        if (xBase) { n.setAttribute("x", xBase) }
        if (prevDims) {
          n.setAttribute("x", prevDims.x + prevDims.width + 0.5) // extra space needed?
        }
        n.textContent = space ? "l" : l
        n.setAttribute("class", className)
        t.appendChild(n)
        prevDims = n.getBBox()
      }
      t.removeChild(ts)
    })
  })
}

function fetchChars(parent) {
  return Array.from(parent.children)
}

function random(min, max){
    return (Math.random() * (max - min)) + min;
}

export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
    coverWidth: 1920,
    coverHeight: 1080,
    mask: true,
    tl: null,
  }),
  computed: {
    first() { return this.$store.getters.slide(0).slug },
  },
  methods: {
    ...mapActions([
      "setCurrent",
    ]),
    begin() {
      this.$router.push("/how-to")
    },
  },
  mounted() {
    this.setCurrent(this.first)

    const { image, titleMain, titleSub, byline, cta, divider } = this.$refs
    const tl = gsap.timeline()
    const imageIn = 2
  
    tl.set([image, cta, titleMain, titleSub, divider, byline].flat(), {
      opacity: 0,
      onComplete: () => this.mask = false
    })
    tl.fromTo(image, imageIn,
      { scale: 1.2, opacity: 0, transformOrigin: `${this.coverWidth/2}px ${this.coverHeight/2}px`, },
      { scale: 1, opacity: 1 })
    
    tl.to(titleMain, 0.5, { opacity: 1 })
    tl.to(titleSub, 0.5, { opacity: 1 })

    const restIn = tl.totalDuration()
    tl.to(cta, 1, {opacity: 1}, restIn)

    this.tl = tl
  }
}
</script>

<style lang="scss">
#Cover {
  position: absolute; top: 0; left: 0; width: 100%; height: 100%;

  .cta {
    cursor: pointer;
    .hover-bg { opacity: 0; transition: opacity 0.2s ease; }
    #cta-clip rect {
      // transform: scaleX(0);
      transform-origin: 360px 0px;
      transition: transform 0.4s ease; }
    &:hover {
      .hover-bg { opacity: 1 }
      #cta-clip rect {
        transform-origin: 0px 0px;
        transform: scaleX(1); }
    }
  }
}
#HomeMask {
  position: absolute; top: 0; left: 0; width: 100%; height: 100%;
  background: #0A0A0A;
}
.main-title {
  filter: drop-shadow(0 0 0.1em rgba(0,0,0,0.25));
}
</style>

<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        
        <foreignObject x="-80" y="25" :width="607 * 2.5" :height="420 * 2.5">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/hotevilla/a.jpeg`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 narrative"
      bg="transparent"
			:width="0.5" :tx="0.033" :ty="0.1">
      <p>
        Like Ray, Bennett was particularly taken by visits and interactions with
        surrounding Native communities.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
			:width="0.33" :tx="0.95" :ty="0.8">
      Snake Kiva, Hotevilla. A collaborative work by John W. Bennett, 1937, and his
      son John M. Bennett, 2021.
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-11.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}
</style>
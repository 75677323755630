<template lang="html">
  <div id="FullscreenToggler"
    :style="{top, right}"
    v-bind:class="{show}"
    @touchstart="(e) => e.stopPropagation()"
    @touchend="(e) => e.stopPropagation()"
    @mouseenter="() => uiOverride(true)"
    @mouseleave="() => uiOverride(false)"
    @click="toggle">
    <svg width="100" height="100" viewBox="0 0 100 100">
      <filter id="FullscreenToggler--dropshadow" height="130%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3"/> <!-- stdDeviation is how much to blur -->
        <feOffset dx="0" dy="0" result="offsetblur"/> <!-- how much to offset -->
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.1"/> <!-- slope is the opacity of the shadow -->
        </feComponentTransfer>
        <feMerge>
          <feMergeNode/> <!-- this contains the offset blurred image -->
          <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->
        </feMerge>
      </filter>
      <circle cx="50" cy="50" r="50" fill="rgba(30,30,30,1)" style="filter:url(#FullscreenToggler--dropshadow)" />
      <g class="frame" transform="scale(0.4)" transform-origin="50 50">
        <path d="M0 40 L0 0 L40 0" stroke="rgba(250,250,250,1)" stroke-width="10" fill="transparent" />
        <path d="M0 60 L0 100 L40 100" stroke="rgba(250,250,250,1)" stroke-width="10" fill="transparent" />
        <path d="M60 100 L100 100 L100 60" stroke="rgba(250,250,250,1)" stroke-width="10" fill="transparent" />
        <path d="M100 40 L100 0 L60 0" stroke="rgba(250,250,250,1)" stroke-width="10" fill="transparent" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data: () => ({
    baseStyles: {
      top: "6em",
      right: "0.5em",
    },
    top: 0,
    right: 0,
  }),
  mounted() {
    this.setPosition()
    window.addEventListener("resize", this.setPosition)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setPosition)
  },
  methods: {
    toggle() {
      const elem = document.querySelector("#app")
      if (!document.fullscreenElement) {
        elem.webkitRequestFullscreen().catch(err => {
          alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen()
      }
    },
    setPosition() {
      const frame = document.getElementById("Frame").getBoundingClientRect()
      // frame["top"/"left"] account for edge
      this.top = `calc(${this.baseStyles.top} + ${(frame.y || frame.top) + frame.height * 0.01}px)`
      this.right = `calc(${this.baseStyles.right} + ${(frame.x || frame.left) + frame.width * 0.01}px)`
    },
  },
  props: [
    "show",
    "uiOverride",
  ]
}
</script>

<style lang="scss" scoped>
#FullscreenToggler {
  position: fixed; width: 3em; height: 3em; opacity: 0; cursor: pointer;
  transition: opacity 0.25s ease; background: rgba(200,200,200,1); border-radius: 50%;
  display: flex;
  &.show { opacity: 1; }
  svg {
    width: 100%; height: auto; margin: auto; overflow: visible;
    .frame { transform-origin: 50px 50px; } }
  &:hover {
    animation: fullscreen-toggler 1s ease infinite;
  }
}

@keyframes fullscreen-toggler {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}
</style>

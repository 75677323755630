<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        
        <image x="-520" y="-100" :width="607 * 5" :height="420 * 5" :xlink:href="`${publicPath}scenes/totem-2/a.jpeg`" />

        <foreignObject x="1050" y="60" :width="607*0.85" :height="705*0.85">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/totem-2/b.jpeg`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1 narrative white fancy-absolute"
      bg="transparent"
			:width="0.5125" :tx="0.033" :ty="0.125">
      <p>
        Now fully stocked with film, Ray goes on another trip through Monument Valley
        with Martin Gambee and Edward Beckwith.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="d2"
			:width="0.3866" :tx="0.95" :ty="0.8">
      <p>
        <em>
          “Drove right straight across the sage-covered desert to the foot of the Totem.”
        </em>
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-13.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped>
	.entry-title {
		margin-bottom: 0.5em;
	}
  .d1 p {
    margin: 0; font-size: 1.2em;
    -webkit-text-stroke-width: 0.025em;
    -webkit-text-stroke-color: rgba(0,0,0,0.33);
  }
  .d2 p {
    font-size: 1.2em;
    margin: 0;
  }
</style>
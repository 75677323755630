<template lang="html">
  <div id="Escape"
    @touchstart="(e) => e.stopPropagation()"
    @touchend="(e) => e.stopPropagation()">
    <div id="EscapeCta"
      ref="cta" :style="{top: ctaTop, right: ctaRight}"
      v-bind:class="{show, active}" @click="open"
      @mouseenter="() => uiOverride(true)"
      @mouseleave="() => active ? null : uiOverride(false)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.31 132.31">
          <path class="bird" d="M92.38,29.12c-4.06-2.13-9,1.31-11.14,5.36S78.38,43.32,75.66,47c-4.79,6.5-14.14,7.6-20.33,12.78-4.06,3.4-6.49,8.32-9.79,12.46S37.25,80,32.05,79c6.66,2.9,12.39,7.86,19.36,9.94a26.6,26.6,0,0,0,23.15-4.26,28.56,28.56,0,0,0,11.1-20.89c0.4-6.52-1.39-13-1-19.54s3.83-13.73,10.22-15" stroke="white" stroke-width="2" fill="white"></path>
          <path class="bird" d="M50.06,111.43a26.86,26.86,0,0,1-21.3,11.26c3.3,3.64,7.24,7,12,8.27s10.4,0.22,13.42-3.66c4.38-5.6,1.7-13.79,3.13-20.76,0.34-1.65,1-3.39,2.5-4.2a8.21,8.21,0,0,0-4.58,2.06C53,106.33,51.73,109,50.06,111.43Z" stroke="white" stroke-width="2" fill="white"></path>
          <path class="bird" d="M34.48,44.14c-2.77-1.78-6.61-.58-9,1.69s-3.77,5.39-5.42,8.22A38.76,38.76,0,0,1,1.57,70a14,14,0,0,0,23.12-8.26c0.63-3.85-.35-7.88.69-11.64s5.73-7,8.74-4.51" stroke="white" stroke-width="2" fill="white"></path>
        </svg>
    </div>
    <div id="EscapeOverlay"
      :style="{display: active ? 'flex' : 'none'}">
      <div id="EscapePopup">
        <Menu :close="close" />
      </div>
    </div>
  </div>
</template>

<script>
import ResizeObserver from "resize-observer-polyfill"
import RadButton from "./RadButton"
import Menu from "./Menu"
export default {
  data: () => ({
    ctaTop: 0,
    ctaRight: 0,
  }),
  mounted() {
    this.setCtaPosition()
    this.ro = new ResizeObserver((entries, observer) => {
      this.setCtaPosition()
    })
    this.ro.observe(document.body)

    this.setCtaPosition()
  },
  beforeDestroy() {
    this.ro.unobserve(document.body)
  },
  methods: {
    close() {
      this.uiOverride(false)
      this.set(false)
    },
    open() {
      this.uiOverride(true)
      this.set(true)
    },
    setCtaPosition() {
      const frame = document.getElementById("Frame").getBoundingClientRect()
      // frame["top"/"left"] account for edge
      this.ctaTop = `${Math.floor(frame.y || frame.top)}px`
      this.ctaRight = `${Math.floor(frame.x || frame.left)}px`
    },
  },
  props: [
    "set",
    "show",
    "active",
    "uiOverride",
  ],
  components: {
    RadButton,
    Menu,
  }
}
</script>

<style lang="scss">
@mixin wk-text-border($fill, $stroke, $width) {
  -webkit-text-fill-color: $fill; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: $width;
  -webkit-text-stroke-color: $stroke; }
@mixin compat-text-border($fill, $stroke, $width) {
  color: $fill;
  text-shadow:
   (-$width) (-$width) 0 $stroke,
    $width (-$width) 0 $stroke,
    (-$width) $width 0 $stroke,
    $width $width 0 $stroke; }
$white: white;
$black: rgba(30,30,30,1);
$teal: #69AB9E;
$gold: #E38900;
#EscapeOverlay {
  position: absolute;
  width: 100vw; height: 100vh;
  z-index: 1; top: 0; left: 0;
}
#EscapePopup {
  position: relative;
  .actions { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em; margin-top: 1.5em; }
  .rad-button {
    width: 100%; font-size: 0.9em; border-radius: 1.5em; background: white;
    margin-right: 1em;
  }
  a.how-to { display: block; margin-top: 1em; font-size: 1em; color: $black; }
}
#EscapeCta {
  position: absolute; width: 4em; opacity: 0; cursor: pointer;
  transition: opacity 0.25s ease; background: $black;
  svg {
    width: 100%; display: block; z-index: 1; position: relative;
    .bird {
      opacity: 0; transition: opacity 0.2s, transform 0.2s;
      &:first-of-type { opacity: 1; transform: scale(1.5) translate(0.1em, 0.25em); transform-origin: 66.155px 66.155px; } }
  }
  &.show { opacity: 1; }
  &:after {
    content: "MENU"; display: block; position: relative; transition: all 0.2s ease;
    text-align: center; z-index: 1; margin: 0.2em 0; font-weight: bold;
    font-size: 0.8em; opacity: 1;
    color: $white; }
  &:hover, &.active {
    svg {
      .bird {
        opacity: 1; transform: translate(10px, -10px);
        &:first-of-type { transform: scale(1) translate(1em, -1em); }
      } }
    &:after {
      opacity: 1;
    } } }
@media screen and (max-width: 480px) and (min-height: 550px) {
  #EscapeCta { top: 0em!important; right: 0em!important;
    &:after { transform: translateY(0); } }
}
</style>

<template lang="html">
  <span class="loader">
    <span class="birds">
      <svg class="bird" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.05 50.88"><path d="M50.38,25.22c-4-1.2-8.23,1.71-10.26,5.35s-2.56,7.91-3.71,11.91A38.39,38.39,0,0,1,12.83,67.58c6.15,3.4,13,6,20,5.63S47,68.68,49.31,62c3.32-9.62-4.09-19.44-5.4-29.53-0.32-2.43-.17-5.18,1.53-7s5.33-1.59,6.06.76" transform="translate(-10.41 -23.36)" fill="#fff" stroke="#888" stroke-miterlimit="10" stroke-width="1"/></svg>
      <svg class="bird" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.09 77.88"><path d="M178.48,12.31c-5-2.63-11.09,1.62-13.74,6.61s-3.52,10.9-6.88,15.45c-5.91,8-17.43,9.37-25.07,15.76-5,4.19-8,10.25-12.07,15.37s-10.22,9.58-16.64,8.38c8.22,3.58,15.28,9.69,23.87,12.25a32.8,32.8,0,0,0,28.54-5.26,35.22,35.22,0,0,0,13.69-25.76c0.49-8-1.72-16.05-1.29-24.09s4.72-16.93,12.61-18.55" transform="translate(-95.61 -10.51)" fill="#fff" stroke="#888" stroke-miterlimit="10" stroke-width="2"/></svg>
      <svg class="bird" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.89 47.13"><path d="M220.09,29.53c1.29-.21-0.21-2.12-1.52-2.05-6.75.37-11.28,7-13.72,13.35s-4.21,13.4-9.54,17.56c-2,1.52-4.43,2.73-5.29,5-1.22,3.28,1.63,6.78,4.84,8.17,7,3,16.18-1.12,18.54-8.38,2-6.18-.53-12.8-1.05-19.27s2.78-14.59,9.27-14.6" transform="translate(-188.73 -26.48)" fill="#fff" stroke="#888" stroke-miterlimit="10" stroke-width="1"/></svg>
      <svg class="bird" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.03 51.36"><path d="M277.28,36.29c-1.08-4.31-3.21-9.54-7.65-9.73-4.23-.18-6.92,4.58-7.56,8.76s-0.42,8.83-3,12.15c-4.76,6-16.31,4.38-19.25,11.48-1.27,3.07-.21,6.67,1.67,9.42a17.59,17.59,0,0,0,31.62-14.1c-1-4.21-3.6-8-3.92-12.34s3.39-9.41,7.49-8" transform="translate(-238.22 -25.56)" fill="#fff" stroke="#888" stroke-miterlimit="10" stroke-width="1"/></svg>
      <svg class="bird" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.05 50.88"><path d="M50.38,25.22c-4-1.2-8.23,1.71-10.26,5.35s-2.56,7.91-3.71,11.91A38.39,38.39,0,0,1,12.83,67.58c6.15,3.4,13,6,20,5.63S47,68.68,49.31,62c3.32-9.62-4.09-19.44-5.4-29.53-0.32-2.43-.17-5.18,1.53-7s5.33-1.59,6.06.76" transform="translate(-10.41 -23.36)" fill="#fff" stroke="#888" stroke-miterlimit="10" stroke-width="1"/></svg>
      <svg class="bird" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.09 77.88"><path d="M178.48,12.31c-5-2.63-11.09,1.62-13.74,6.61s-3.52,10.9-6.88,15.45c-5.91,8-17.43,9.37-25.07,15.76-5,4.19-8,10.25-12.07,15.37s-10.22,9.58-16.64,8.38c8.22,3.58,15.28,9.69,23.87,12.25a32.8,32.8,0,0,0,28.54-5.26,35.22,35.22,0,0,0,13.69-25.76c0.49-8-1.72-16.05-1.29-24.09s4.72-16.93,12.61-18.55" transform="translate(-95.61 -10.51)" fill="#fff" stroke="#888" stroke-miterlimit="10" stroke-width="2"/></svg>
      <span class="msg">Loading...</span>
    </span>
  </span>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
$len__flight: 600ms;
// $curve__flight: cubic-bezier(.65,.12,.45,1.62);
$curve__flight: linear;

.loader {
  position: absolute; top: 0; left: 0; height: 100%; width: 100%;
  color: white; animation: fade-in 1s ease both;
  background: #0A0A0A;
  // background: red;
  .birds {
  animation: fade-in 1s ease both 1s;
  letter-spacing: 0.1em; position: absolute; left: 50%; top: 50%;
  transform: translate(-50%, -50%); font-size: 0.8em;
  .msg {
    display: block; text-align: center; position: relative;
    top: 50%; left: 50%; transform: translate(-50%, -50%);
    animation: phase 1s ease alternate; }
  .bird {
    width: 33%; height: auto; opacity: 1; position: absolute;
    &:nth-of-type(1n) {
      left:-12.5%; bottom: 25%;
      animation: move-bird-bottom $len__flight $curve__flight infinite; }
    &:nth-of-type(2n) {
      left: 37.5%;
      animation-delay: -1.8 * $len__flight / 2; }
    &:nth-of-type(3n) {
      left: 75%;
      animation-delay: -0.5 * $len__flight / 2; }
    &:nth-of-type(4n) {
      left: 75%;
      bottom: 0%;
      animation-delay: -1.5 * $len__flight / 2; }
    &:nth-of-type(5n) { left: -37.5%; animation-delay: -0.65 * $len__flight; }
    &:nth-of-type(6n) { left: 0%; animation-delay: -2.75 * $len__flight / 2; }
    &:nth-of-type(7n) { left: 25%; animation-delay: -2.5 * $len__flight / 2; }
    &:nth-of-type(8n) { left: 100%; animation-delay: -2.6 * $len__flight / 2; } } } }

@keyframes move-bird-bottom {
  0% { opacity: 0; transform: translate(-80%,150%); }
  33% { opacity: 1; transform: translate(-80%,150%); }
  80% { opacity: 1; transform: translate(80%,-150%); }
  100% { opacity: 0; transform: translate(80%,-150%); }
}

@keyframes fade-in {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
</style>

<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <g clip-path="url(#clip-frame)">
        
        <foreignObject
          :x="canvasWidth/2 - (canvasWidth * 0.75)/2"
          :y="canvasHeight/2 - (canvasHeight * 0.75)/2 - 50"
          :width="canvasWidth * 0.75"
          :height="canvasHeight  * 0.75">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/hopi-snake-dance/a.jpeg`" />
          </picture>
        </foreignObject>
      </g>
    </svg>
    <SceneDialogue
      class="d1 futura-passata"
      bg="transparent"
			ref="dialogue-1"
			:width="0.8" :tx="0.033" :ty="0.04">
      Toward the end of the summer, the whole Expedition group takes a trip to visit Hopi.
    </SceneDialogue>
    <SceneDialogue
      class="d2 bold"
      bg="transparent"
			ref="dialogue-2"
			:width="0.375" :tx="0.02" :ty="0.45">
			<p><em>
        “Got to Walpi, our destination at 5:30! We’re staying here overnight to witness
        the Hopi Snake dance in the morning. Roamed about town for a while. It’s built
        on top of a long ridgelike mesa. The town covers the entire summit. Very
        picturesque. In the center of one of the streets centuries of wagon traffic
        has worn grooves as much as a foot into the solid stone!” 
			</em></p>
    </SceneDialogue>
    <SceneDialogue
      class="d3"
			ref="dialogue-3"
      bg="transparent"
			:width="0.6" :tx="0.5" :ty="0.915">
			<p><em>
        “Tomorrow we’ll witness an ancient Hopi rite that few people have been able to
        see. No picture taking however, because they allow no cameras in the village.” 
			</em></p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-19.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-3"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-3"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
  .d1 {
    color: white;
  }
  .d2 {
    p {
      font-size: 0.9em;
      margin: 0;
      color: white;
      text-shadow:
        .03em  .03em 0 #000,
        -.01em -.01em 0 #000,  
        .01em -.01em 0 #000,
        -.01em  .01em 0 #000,
        .01em  .01em 0 #000;
    }
  }
  .d3 {
    p {
      font-size: 1em;
      margin: 0;
      color: white;
      text-align: center;
    }
  }
	.entry-title {
		margin-bottom: 0.5em;
	}
</style>
<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" fill="white" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/bg-color.jpg`" />
        </picture>
      </foreignObject>

      <g class="outline grass">
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/plant-1.png`" />
          </picture>
        </foreignObject>
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/plant-2.png`" />
          </picture>
        </foreignObject>
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/plant-3.png`" />
          </picture>
        </foreignObject>
      </g>
      
      <g class="outline man">
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/man-1.png`" />
          </picture>
        </foreignObject>
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/man-2.png`" />
          </picture>
        </foreignObject>
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/man-3.png`" />
          </picture>
        </foreignObject>
      </g>

      <g class="outline star">
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/star-1.png`" />
          </picture>
        </foreignObject>
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/star-2.png`" />
          </picture>
        </foreignObject>
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}scenes/darkroom-night/star-3.png`" />
          </picture>
        </foreignObject>
      </g>

      <image ref="moon" x="1106" y="85" :width="169" :height="178" :xlink:href="`${publicPath}scenes/darkroom-night/moon.png`" />
    </svg>
    <SceneDialogue
      ref="dialogue-1"
      class="fancy-absolute"
      :width="0.315" :tx="0.05" :ty="0.6">
      Ray arrives back at base camp for supplies and gets stuck waiting
      for a delivery of film. While he waits, he helps Max Littlesalt with
      the construction of the darkroom.
    </SceneDialogue>
    <SceneDialogue
      class="fancy-absolute"
      ref="dialogue-2"
      :width="0.32" :tx="0.95" :ty="0.66">
      They continue to dig and reinforce the walls.
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-12.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["moon"], { opacity: 0, y: 10 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["moon"], { duration: 1, opacity: 1, y: 0 }, "-=1")
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
$anim-man-length: 0.6s;

.outline.man foreignObject {
  animation-name: man; animation-iteration-count: infinite;
  animation-timing-function: steps(1,end); animation-duration: #{$anim-man-length};
  &:nth-of-type(1) { animation-delay: 0s; }
  &:nth-of-type(2) { animation-delay: -#{$anim-man-length * .34}; }
  &:nth-of-type(3) { animation-delay: -#{($anim-man-length * .66) }; }
}

@keyframes man {
  0% { opacity: 1; }
  33% { opacity: 0; }
  100% { opacity: 0; }
}

$anim-grass-length: 0.6s;

.outline.grass foreignObject {
  animation-name: grass; animation-iteration-count: infinite;
  animation-timing-function: steps(1,end); animation-duration: #{$anim-grass-length};
  &:nth-of-type(1) { animation-delay: 0s; }
  &:nth-of-type(2) { animation-delay: -#{$anim-grass-length * .34}; }
  &:nth-of-type(3) { animation-delay: -#{($anim-grass-length * .66) }; }
}

@keyframes grass {
  0% { opacity: 1; }
  33% { opacity: 0; }
  100% { opacity: 0; }
}

$anim-star-length: 1.2s;

.outline.star foreignObject {
  animation-name: star; animation-iteration-count: infinite;
  animation-timing-function: linear; animation-duration: #{$anim-star-length};
  &:nth-of-type(1) { animation-delay: 0s; }
  &:nth-of-type(2) { animation-delay: -#{$anim-star-length * .34}; }
  &:nth-of-type(3) { animation-delay: -#{($anim-star-length * .66) }; }
}

@keyframes star {
  0% { opacity: 1; }
  33% { opacity: 0; }
  100% { opacity: 1; }
}
</style>
<template lang="html">
  <image
    :width="width"
    :height="height"
    :xlink:href="src" />
</template>

<script>
export default {
  data: function() { return {
    src: this.srcs[this.srcs.length - 1]
  } },
  mounted() {
    // const buffer = new Image()
    // buffer.onload = () => this.src = buffer.src
    // buffer.src = this.srcs[1]
  },
  props: [
    'width',
    'height',
    'srcs',
  ]
}
</script>

<style lang="css" scoped>
</style>

<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <rect ref="frame" id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" fill="white" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

			<g clip-path="url(#clip-frame)">
				<foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
					<picture>
						<img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}bg-texture.jpg`" />
					</picture>
				</foreignObject>
				
				<image ref="map" x="200" y="-140" :width="canvasWidth*1.2" :height="canvasHeight*1.2" :xlink:href="`${publicPath}scenes/map-marsh-pass/map.png`" />
			</g>
    </svg>
    <SceneDialogue
			class="d1 futura-passata"
			ref="dialogue-1"
			bg="transparent"
			:width="0.75" :tx="0.033" :ty="0.1">
			<p>
				Expedition filmmaker, <span class="opti-morgan">Ray Garner</span>, wakes up in his sleeping bag and grabs his
				journal...
			</p>
    </SceneDialogue>
    <SceneDialogue
			class="d2"
			ref="dialogue-2"
			:bg="'transparent'"
			:width="0.36" :tx="0.033" :ty="0.475">
			<h3 class="entry-title futura-passata">
				<span class="heading futura-passata">Journal Entry</span>
				<!-- <span class="sm"> &mdash; </span> -->
				<span class="n2 futura-passata">July 1, 1937</span>
			</h3>
			<p><em>
				<strong class="q agrandir-narrow">“</strong>Got up at 6:30. Saw Marsh Pass for the first time. It lies between Black and
				Skeleton Mesas and is the gateway to Tsegi (pronounced ‘say-gee’) Canyon.
				Thusly: 
			</em></p>
    </SceneDialogue>
    <SceneDialogue
			class="d3 fancy-absolute"
			ref="dialogue-3"
			:width="0.4" :tx="0.25" :ty="0.825">
			<p><em>
				The red cliffs of Skeleton are very beautiful – an
				excellent location for a camp. Spent the morning getting unpacked and
				organized.”
			</em></p>
    </SceneDialogue>
		<SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-2.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    gsap.set(this.$refs["map"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-3"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["map"], { duration: 1, opacity: 1 }, "+=0.5")
    tl.to(this.$refs["dialogue-3"].$el, { duration: 1, opacity: 1 }, "+=0.5")
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.d1 {
		p {
			font-size: 1.5em;
			line-height: 1.25em;
		}
	}

	.d2 {
		p {
			line-height: 1.5em;
			font-size: 1.2em;
			margin: 0.75em auto 0em;
		}
	}

	.d3 {
		p {
			line-height: 1.5em;
			font-size: 1.25em;
			margin: 0;
		}
	}

	.heading {
		font-size: 1.25em;
		display: inline-block;
		padding-bottom: 0.15em;
		border-bottom: 0.05em solid currentColor;
		vertical-align: middle;
	}

	.q {
		font-size: 1.8em;
		display: inline-block;
		vertical-align: bottom;
		margin-right: 0.15em;
	}

	.entry-title {
		// margin-bottom: 0.5em;
		font-size: 1.2em;
	}

	.n2 { margin-left: 0.66em; font-size: 1em; }
</style>
<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="white d1"
      bg="transparent"
			:width="0.9" :tx="0.5" :ty="0.5">
      <h3 class="title futura-passata">
        Pages Notes Continued:
      </h3>
      <p class="sm">Page 9: Image at left, archaeologist Ralph Beals at the Cobra Head Canyon site, courtesy of the Fowler Museum at UCLA. Image at right, John W. Bennett in archaeological pit in Arizona, from The John W. Bennett Papers, SPEC.RARE.CMS.119, Special Collections at Ohio State University. Used by permission.</p>
      <p class="sm">Page 10: A note from James P. Bennett, son of John W. Bennett, on the images at center and right: Eastern white pine (Pinus strobus). This tree is on our property in the Driftless region of southwest Wisconsin. It is called a supercanopy tree because it is much higher than the trees around it (and probably much older). This makes the tree attractive to eagles, and if you look closely you will see an eagle nest. We have had a pair of eagles in this tree for years. John W Bennett may have painted the watercolor of pinyon pine after growing up in Wisconsin seeing eastern white pines in the Driftless.</p>
      <p class="sm">Page 13: Photographs by Ray Garner, used by permission of the descendants of Ray Garner. </p>
      <p class="sm">Page 14: Photographs by Edward P. Beckwith, courtesy of the Explorer’s Club.</p>
      <p class="sm">Page 15: Page from journal of Ray Garner, used by permission of the descendants of Ray Garner.
      Photograph by Edward P. Beckwith, courtesy of the Explorer’s Club. A note about the Navajo guide’s name: This is most likely a direct translation of the Navajo name of this man which was not written in Ray’s diary. We were unable to verify the man’s Navajo name.</p>
      <p class="sm">Page 16: Photographs by Robert Branstead, 1934, courtesy of  the Center of Southwest Studies at Fort Lewis College. </p>
      <p class="sm">Page 17: Photograph by Edward P. Beckwith, courtesy of the Explorer’s Club.</p>
      <p class="sm">Page 19: Artwork by Abel Nash, Hopi Artist.</p>
      <p class="sm">Page 20: Postcard from journal of Ray Garner, used by permission of the descendants of Ray Garner. </p>
      <p class="sm">Pages 23-24: Photographs of darkroom and surrounding area by Lithuania Denetso. </p>
    </SceneDialogue>
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const tl = gsap.timeline({ paused: true })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
.sm {
	font-size: 0.85em;
}

.title {
  margin-top: 1em;
}

.content {
  overflow: scroll;
  height: -webkit-fill-available!important;
}
</style>
<template>
  <div
    id="Controls" v-bind:class="{ show }"
    @mouseenter="() => uiOverride(true)"
    @mouseleave="() => uiOverride(false)"
    @touchend="e => e.stopPropagation()"
    :style="{ bottom, width, left, right, height: width, pointerEvents: show ? 'all' : 'none' }">
    <AudioToggle />
  </div>
</template>

<script>
import ResizeObserver from "resize-observer-polyfill"
import AudioToggle from "../components/AudioToggle.vue"

export default {
  data: () => ({ 
    bottom: 0,
    right: 0,
    width: 0,
    left: 'auto',
  }),
  mounted() {
    this.setPosition()
    this.ro = new ResizeObserver((entries, observer) => {
      this.setPosition()
    })
    this.ro.observe(document.body)
  },
  methods: {
    setPosition() {
      const { width, height, x, y, left, top } = document.getElementById("Frame").getBoundingClientRect()
      // NOTE: frame["top"/"left"] account for edge
      this.bottom = `${((y||top) + width * 0.025) + width * 0.05}px`
      this.width = `${width * 0.045}px`
      this.right = `${(x||left) + width * 0.025}px`
    },
  },
  components: {
    AudioToggle
  },
  props: [ "show", "uiOverride" ]
}
</script>

<style>
#Controls { position: absolute; opacity: 0; transition: opacity 0.25s ease; }
#Controls.show { opacity: 1; }
#Controls .audio-toggle { display: block; height: 100%; transition: opacity 0.25s ease; }
#Controls .audio-toggle svg {  width: 75%; }

@media screen and (max-width: 480px) {
  #Controls {
    position: fixed; bottom: 1em!important; right: 'auto'!important;
    left: 2%!important; height: 3em!important; width: 3em!important; }
  #Controls .audio-toggle { width: 3em; height: 3em; }
}
</style>
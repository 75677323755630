<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id='shadow' color-interpolation-filters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="20" flood-opacity="0.33"/>
        </filter>
      </defs>
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>
      <g clip-path="url(#clip-frame)">
        <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
          <picture>
            <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}bg-texture.jpg`" />
          </picture>
        </foreignObject>
        <image
          ref="max"
          :x="-2244"
          :y="-473"
          :width="3204"
          :height="2255"
          :xlink:href="`${publicPath}scenes/introduction/max.jpg`"
        />
        <image
          ref="ray"
          :x="1131"
          :y="-88"
          :width="907"
          :height="1657"
          :xlink:href="`${publicPath}scenes/introduction/ray.jpg`"
        />
      </g>
    </svg>
    <SceneDialogue
			ref="dialogue-1"
      class="d1"
			:width="0.55" :tx="0.5" :ty="0.425">
      <p style="margin-bottom: 1em" class="futura-passata">
        Welcome to The ONWARD Project’s newest narrative website, West of My Dreams:
        A Story of Ray Garner.
      </p>
      <p style="margin-bottom: 1em" class="">
        This story takes place during the Rainbow Bridge Monument
        Valley Expedition (RBMVE), an interdisciplinary research program that took place
        in the American Southwest from 1933-1938. Groups of students, scientific
        specialists, and artists worked collaboratively to study  the region. They relied
        on the guidance of local Native American people, primarily Navajo and San Juan
        Paiute, to safely exist in and move through the landscape. Because of this, the
        Expedition’s academic and scientific success is directly tied to Native knowledge.
      </p>
      <p class="">
        In this particular story we focus on Ray Garner, a young Expedition filmmaker,
        who carefully documented his days in journals throughout his time on the 1937
        Expedition. We also focus on Max Littlesalt, a local Navajo man who was a guide,
        packer, and interpreter for the RBMVE. In this narrative we bring their stories
        as Max and Ray work together to build a darkroom and support the photographic
        goals of the Expedition. This darkroom still exists today near Kayenta in
        Northern Arizona.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="dialogue-2"
      class="d2"
			:width="0.55" :tx="0.5" :ty="0.9">
      <p class="quote">
        <em>
          “It means a lot to me to have my father remembered in this special way.”
        </em>
      </p>
      <p class="attribution">
      - Gay Garner Mackintosh, daughter of Ray Garner
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/_Intro.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(this.$refs["scene"], { opacity: 0 })
    gsap.set(this.$refs["max"], { opacity: 0 })
    gsap.set(this.$refs["ray"], { opacity: 0 })
    gsap.set(this.$refs["dialogue-1"].$el, { opacity: 0 })
    gsap.set(this.$refs["dialogue-2"].$el, { opacity: 0 })
    tl.to(this.$refs["scene"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["max"], { duration: 1, opacity: 1 })
    tl.to(this.$refs["ray"], { duration: 1, opacity: 1 }, "-=0.5")
    tl.to(this.$refs["dialogue-1"].$el, { duration: 1, opacity: 1 })
    tl.to(this.$refs["dialogue-2"].$el, { duration: 1, opacity: 1 }, "+=0.5")
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.entry-title {
		margin-bottom: 0.5em;
	}
  .big {
    font-size: 1.5em;
  }

  .d1 {
    p { margin: 0; }
  }

  .d2 {
    p {
      margin: 0 auto;
      text-align: center;
    }
    .quote {
      font-size: 0.9em;
      font-weight: bold;
    }
    .attribution {
      // font-size: 0.8em;
      margin: 0.25em 0.5em 0 0;
    }
  }
</style>
<template>
  <audio ref="track" :src="src" />
</template>

<script>
import { mapActions } from "vuex"

export default {
  watch: {
    active(curr, prev) {
      const { track } = this.$refs
      if (curr && !prev && this.play) { track.play() }
      if (!curr && prev) {
        track.pause()
        track.currentTime = 0
      }
    },
    play(curr, prev) {
      const { track } = this.$refs
      if (curr && !prev && this.active) { track.play() }
      if (!curr && prev) {
        track.pause()
        track.currentTime = 0
      }
    },
  },
  computed: {
    active() { return this.$store.state.audioActive },
    src() { return this.$store.state.audioSrc },
    pulse() { return this.$store.state.audioPulse },
    play() { return this.$store.state.audioPlay },
  },
  mounted() {
    if (this.active) { this.$refs.track.play() }
  },
  methods: {
    ...mapActions(["setAudioPulse"])
  },
}
</script>

<style>
  audio { display: none; }
</style>
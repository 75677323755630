<template>
  <div ref="slide" class="slide">
    <svg class="canvas" ref="scene" :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`" xmlns="http://www.w3.org/2000/svg">
      <rect id="Frame" x="0" y="0" :width="canvasWidth" :height="canvasHeight" />
      <clipPath id="clip-frame">
        <use xlink:href="#Frame"/>
      </clipPath>

      <foreignObject x="0" y="0" :width="canvasWidth" :height="canvasHeight">
        <picture>
          <img :style="{width: `100%`, height: `auto`}" :src="`${publicPath}bg-texture.jpg`" />
        </picture>
      </foreignObject>

      <g clip-path="url(#clip-frame)">
        <image ref="a" x="60" y="275" :width="1534 / 1.5" :height="1083 / 1.5" :xlink:href="`${publicPath}scenes/cobra-head/a.jpeg`" />
        <image ref="b" x="1150" y="50" :width="480 * 1.5" :height="640 * 1.5" :xlink:href="`${publicPath}scenes/cobra-head/b.jpeg`" />
      </g>
    </svg>
    <SceneDialogue
			ref="d1"
      class="d1 narrative fancy-absolute"
      bg="transparent"
			:width="0.55" :tx="0.033" :ty="0.125">
      <p>
        Still in Cobra Head Canyon, Ray also meets a small group of Expedition
        archaeologists.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="d2"
      class="d2"
      bg="white"
			:width="0.266" :tx="0.025" :ty="0.35">
      <p>
        Ralph Beals and John W. Bennett take their work very seriously.
      </p>
    </SceneDialogue>
    <SceneDialogue
			ref="d3"
      class="d3"
      bg="white"
			:width="0.48" :tx="0.85" :ty="0.775">
      <p>
        They are impatient as Ray takes film footage of their activities.
      </p>
    </SceneDialogue>
    <SceneAudio ref="scene-audio" :src="`${publicPath}audio/mp3/page-9.mp3`" />
  </div>
</template>

<script>
import Template from "./Template.vue"
import gsap from "gsap"

export default {
  extends: Template,
  mounted() {
    const { scene, d1, d2, d3, a, b } = this.$refs

    const hasAudio = !!this.$refs['scene-audio']
    this.setShowAudioControls(hasAudio)
    const tl = gsap.timeline({ paused: true, onComplete: hasAudio ? this.audioPlay : null })
    /* must set with gsap since tl is paused */
    gsap.set(scene, { opacity: 0 })
    gsap.set(d1.$el, { opacity: 0 })
    gsap.set(d2.$el, { opacity: 0 }, 0)
    gsap.set(d3.$el, { opacity: 0 })
    gsap.set(a, { opacity: 0 })
    gsap.set(b, { opacity: 0 })
    gsap.set(".d1 p", { scale: 1.1, })
    
    tl.to(scene, { opacity: 1 })
    tl.to(d1.$el, { duration: 1, opacity: 1 })
    tl.to(".d1 p", { duration: 0.66, scale: 1, }, "-=1")
    tl.to(a, { duration: 1, opacity: 1 }, "+=0.66")
    tl.to(b, { duration: 1, opacity: 1 }, "-=0.66")
    tl.to(d2.$el, { duration: 1, opacity: 1 })
    tl.to(d3.$el, { duration: 1, opacity: 1 }, "+=1")
    
    this.tl = tl
  },
  methods: {
    enter() {
      this.tl.play()
    },
  }
}
</script>

<style scoped lang="scss">
	.entry-title {
		margin-bottom: 0.5em;
	}

  .d1 {
    p { font-size: 1.33em; margin: 0.1em 0 0; }
  }

  .d2, .d3  {
    padding: 0.5em;
    box-shadow: 0px 0px 3em 0px rgba(0,0,0,0.33);

    p {
      margin: 0;
      font-size: 1.1em;
    }
  }
</style>